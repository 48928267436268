import { VaquinhaService } from 'src/providers/vaquinha.service';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ENV } from 'src/environments/environment-variables.token';
import { HttpService } from 'src/providers/http.service';
import { ScrollService } from 'src/providers/scroll.service';
import { Subscription } from 'rxjs';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { CandidateService } from '../../providers/candidate.service';
import { MatStepper } from '@angular/material/stepper';
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/providers/auth.service';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  // animations: [
  // 	trigger('fade', [
  // 		transition('void => *', [style({ opacity: 0 }), animate('800ms', style({ opacity: 1 }))]),
  // 		transition('* => void', [style({ opacity: 1 }), animate('800ms', style({ opacity: 0 }))]),
  // 	])
  // ]
})
export class HomeComponent implements OnInit {
  title = 'Quero Apoiar - Home';
  faPlayCircle = faPlayCircle;
  public email: any;
  public name: any;
  public telefone: any;
  public senha: any;
  public senhaConf: any;
  public error: any;
  public success: any;
  public loading: any;
  public headerHide: any;
  public already: any;
  public terms = false;
  public daysLeft: any;
  public showVideo = true;
  public completo = false;
  public isDisabled = false;
  public vaquinhaEleitoral: any;
  public vaquinhaQuantity: any;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  userForm = this.formBuilder.group({
    email: ['', [Validators.required]],
  });
  eventSubscription: any;

  public elements = ['cadastro', 'comofunciona', 'contato'];
  public currentEl = 0;
  private offsetTop = 0;
  public formGeneralData: any;
  public formPersonal: any;
  public formCidade: any;
  public stepper: MatStepper;
  public stepperCount = 0;
  public arrCities = [];
  public arrStates: any;
  public city: '';
  public state: '';
  public busca: any;
  public url: any;
  public errorAlias = false;
  public candidatos: any;
  public message: any;
  public values: any;
  public page = 0;
  public imgUrl: any;
  private errorCpf = false;

  @ViewChild('depoimentos') depoimentos: ElementRef;
  @ViewChild('produtos') produtos: ElementRef;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private scrollService: ScrollService,
    private VaquinhaService: VaquinhaService,
    private candidateService: CandidateService,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private titleService: Title,
    private auth: AuthService,
    public utils: UtilsProvider,
    private metaTagService: Meta,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENV) public ENV: any
  ) {}

  ngOnInit() {
    this.getVaquinha();
    window.scrollTo(0, 0);
    const self = this;
    //this.titleService.setTitle(this.title);
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Bem vindo ao Quero Apoiar',
    });
    const countDownData = new Date('May 15, 2022 00:00:00').getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      // console.log("now", now);
      const distance = countDownData - now;
      const days = Math.ceil(distance / (1000 * 60 * 60 * 24));
      this.daysLeft = days;
    }, 1000);

    // this.subscription = this.scrollService.navItem$.subscribe((item) => {
    // 	this.scrollTo(item);
    // });
    this.candidateService
      .getTopDonations({})
      .then((res) => {
        console.log('response', res);
        if (res.length > 0) {
          this.candidatos = res;
        }

        // if (res[0].user.profile.media) {
        // 	const media = res[0].user.profile.media;
        // 	this.imageUrl = media.host + media.full;
        // }
      })
      .catch((err) => {
        console.log('ERR: ', err);
        throw err;
      });

    // console.log('home env', this.ENV);
    // setInterval(() => {
    // 	this.current = ++this.current % this.img_list.length;
    // }, 2000);

    const email = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    );
    const name = new FormControl('', Validators.compose([Validators.required]));
    const cpf = new FormControl('', Validators.compose([Validators.required]));
    const rg = new FormControl('', Validators.compose([Validators.required]));
    const sexo = new FormControl('', Validators.compose([Validators.required]));
    const telefone = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(15)])
    );
    const password = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const confirmPassword = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        RxwebValidators.compare({ fieldName: 'password' }),
      ])
    );
    this.formPersonal = [
      {
        fields: [
          {
            name: 'name',
            type: 'text',
            placeholder: 'Nome Completo',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'email',
            type: 'email',
            placeholder: 'Email',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'cpf',
            type: 'mask',
            placeholder: 'CPF (Apenas Números)',
            mask: { mask: '000.000.000-00', type: 'num', len: 14 },
            minlength: 14,
            maxlength: 14,
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {
              if (cpf.valid) {
                //cpf.value.replace(/[\.\-]*/gi, "")
                let val = cpf.value.replace(/[\.\-]*/gi, '');
                self.formPersonal[0].builder.controls.cpf.value = val;
                if (!self.utils.cpfVerify(val)) {
                  self.utils.toast({
                    message: 'CPF inválido, verifique os dados inseridos.',
                    action: 'OK!',
                    duration: 10000,
                  });
                  self.errorCpf = true;
                } else {
                  self.errorCpf = false;
                }
              }
            },
          },
          /*{
						name: 'rg',
						type: 'mask',
						placeholder: 'RG (Apenas Números)',
						mask: { mask: "00.000.000-x", len: 12 },
						minlength: 12,
						cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
						blurCallback() {
							self.formPersonal[0].builder.controls.rg.value = rg.value.replace(/[\.\-]*'/gi, "")
						}
					},*/
          {
            name: 'rg',
            type: 'num',
            placeholder: 'RG (Apenas Números ou Letras)',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'sexo',
            type: 'select',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            placeholder: 'Gênero',
            options: {
              fields: {
                value: 'value',
                text: 'title',
              },
              items: this.utils.sexo,
            },
            clickCallback: (data) => {},
          },
          {
            name: 'telefone',
            mask: { phone: true, len: 15 },
            maxlength: 15,
            minlength: 15,
            type: 'mask',
            placeholder: 'Telefone Celular',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {
              self.formPersonal[0].builder.controls.telefone.value =
                telefone.value.replace(/[\(\)\-]*/gi, '');
            },
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Senha',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'confirmPassword',
            type: 'password',
            placeholder: 'Confirme sua Senha',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          name,
          email,
          sexo,
          telefone,
          password,
          cpf,
          rg,
          confirmPassword,
        }),
        validation_messages: {
          name: [{ type: 'required', message: 'Campo obrigatório.' }],
          email: [{ type: 'required', message: 'Campo obrigatório.' }],
          sexo: [{ type: 'required', message: 'Campo obrigatório.' }],
          telefone: [
            { type: 'required', message: 'Campo obrigatório.' },
            {
              type: 'minlength',
              message: 'Preencha corretamente o campo Celular.',
            },
          ],
          rg: [{ type: 'required', message: 'Campo obrigatório.' }],
          cpf: [{ type: 'required', message: 'Campo obrigatório.' }],
          password: [{ type: 'required', message: 'Campo obrigatório.' }],
          confirmPassword: [
            { type: 'required', message: 'Campo obrigatório.' },
            { type: 'compare', message: 'Os campos devem ter o mesmo valor.' },
          ],
        },
      },
    ];

    const municipio = new FormControl(
      '',
      Validators.compose([Validators.required])
    );

    this.formCidade = [
      {
        fields: [
          {
            name: 'municipio',
            type: 'search',
            placeholder: 'Município',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
            clickSearch(item) {
              self.selectCity(item);
            },
          },
        ],
        builder: this.formBuilder.group({
          municipio,
        }),
        validation_messages: {
          municipio: [
            // { type: "required" },
            { type: 'required', message: 'Campo obrigatório.' },
          ],
        },
      },
    ];

    this.createFormGeneralData();
  }
  createFormGeneralData() {
    const self = this;

    const cargos = [
      // { title: 'Prefeito(a)', value: 'Prefeito' },
      // { title: 'Vereador(a)', value: 'Vereador' },
      { title: 'Deputado(a) Estadual', value: 'Deputado Estadual' },
      { title: 'Deputado(a) Federal', value: 'Deputado Federal' },
      { title: 'Deputado(a) distrital', value: 'Deputado distrital' },
      { title: 'Senador(a)', value: 'Senador' },
      { title: 'Governador(a)', value: 'Governador' },
      { title: 'Presidente', value: 'Presidente' },
    ];
    const partidos = [
      { title: 'Agir' },
      { title: 'Avante' },
      { title: 'Cidadania' },
      { title: 'DC' },
      { title: 'MDB' },
      { title: 'NOVO' },
      { title: 'PCB' },
      { title: 'PCO' },
      { title: 'PCdoB' },
      { title: 'PDT' },
      { title: 'PL' },
      { title: 'PMB' },
      { title: 'PMN' },
      { title: 'PP' },
      { title: 'PROS' },
      { title: 'PRTB' },
      { title: 'PSB' },
      { title: 'PSC' },
      { title: 'PSD' },
      { title: 'PSDB' },
      { title: 'PSOL' },
      { title: 'PSTU' },
      { title: 'PT' },
      { title: 'PTB' },
      { title: 'PV' },
      { title: 'Patriota' },
      { title: 'Podemos' },
      { title: 'Podemos' },
      { title: 'Rede' },
      { title: 'Republicanos' },
      { title: 'Solidariedade' },
      { title: 'UP' },
      { title: 'União Brasil' },
    ];
    const estado = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const partido = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const cargo = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const urna = new FormControl('', Validators.compose([Validators.required]));
    const url = new FormControl('', Validators.compose([Validators.required]));
    const civil = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const nacionalidade = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const profissao = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const endereco = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const cep = new FormControl('', Validators.compose([Validators.required]));

    this.formGeneralData = [
      {
        fields: [
          /*{
						name: "municipio",
						type: "search",
						placeholder: "Município",
						cols: "col-lg-6 col-md-6 col-sm-6 col-xs-12",
						blurCallback() { },
						clickSearch(item) {
							self.selectCity(item);
						}
					},*/
          {
            name: 'estado',
            type: 'text',
            placeholder: 'Estado',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            readonly: true,
            blurCallback() {},
          },
          {
            name: 'cep',
            type: 'maskCep',
            placeholder: 'CEP',
            maxlength: 9,
            mask: { mask: '00000-000', len: 9 },
            cols: 'col-lg-4 col-md-4 cols-sm-12 col-xs-12',
            blurCallback() {
              // console.log("teste cep", self.institutionForm[0].builder.controls.cep.value);
              let cepValue = self.formGeneralData[0].builder.controls.cep.value;
              if (cepValue.length !== 0) {
                cepValue = cepValue.replace(/\W+/g, '');
              }
            },
          },
          {
            name: 'endereco',
            type: 'text',
            placeholder: 'Endereço',
            cols: 'col-lg-8 col-md-8 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'profissao',
            type: 'text',
            placeholder: 'Profissão',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'nacionalidade',
            type: 'select',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            placeholder: 'Nacionalidade',
            options: {
              fields: {
                value: 'nome_pais',
                text: 'nome_pais',
              },
              items: this.utils.nacionalidades,
            },
            clickCallback: (data) => {},
          },
          {
            name: 'civil',
            type: 'select',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            placeholder: 'Estado Civil',
            options: {
              fields: {
                value: 'nome',
                text: 'nome',
              },
              items: this.utils.estadoCivil,
            },
            clickCallback: (data) => {},
          },
          {
            name: 'partido',
            // type: "text",
            // placeholder: "Partido",
            // cols: "col-lg-12 col-md-12 col-sm-12 col-xs-12",
            // blurCallback() { }
            type: 'select',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            placeholder: 'Partido',
            options: {
              fields: {
                value: 'title',
                text: 'title',
              },
              items: partidos,
            },
            clickCallback: (data) => {},
          },
          {
            name: 'urna',
            type: 'textHover',
            icon: 'help',
            tooltip:
              'Nome pelo qual é conhecido politicamente e que usará pra fazer campanha',
            placeholder: 'Nome da Urna',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {
              // const value = self.formGeneralData[0].builder.controls.urna.value;
              // console.log("VALUE: ", value);
              // // this.url
              // self.validateAlias(value);
            },
          },
          {
            name: 'url',
            type: 'urlHover',
            placeholder: 'usuario',
            icon: 'help',
            tooltip:
              'Esse será o endereço de sua campanha para ser compartilhado com seus apoiadores',
            customString: 'queroapoiar.com.br/',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {
              const value = self.formGeneralData[0].builder.controls.url.value;
              console.log('VALUE: ', value);
              // this.url
              self.validateAlias(value);
            },
          },
          {
            name: 'cargo',
            type: 'select',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            placeholder: 'Cargo Pretendido',
            options: {
              fields: {
                value: 'value',
                text: 'title',
              },
              items: cargos,
            },
            clickCallback: (data) => {},
          },
        ],
        builder: this.formBuilder.group({
          endereco,
          cep,
          civil,
          profissao,
          nacionalidade,
          estado,
          partido,
          cargo,
          url,
          urna,
        }),
        validation_messages: {
          cep: [{ type: 'required', message: 'Campo obrigatório.' }],
          endereco: [{ type: 'required', message: 'Campo obrigatório.' }],
          estado: [{ type: 'required', message: 'Campo obrigatório.' }],
          partido: [{ type: 'required', message: 'Campo obrigatório.' }],
          cargo: [{ type: 'required', message: 'Campo obrigatório.' }],
          urna: [{ type: 'required', message: 'Campo obrigatório.' }],
          url: [{ type: 'required', message: 'Campo obrigatório.' }],
          profissao: [{ type: 'required', message: 'Campo obrigatório.' }],
          nacionalidade: [{ type: 'required', message: 'Campo obrigatório.' }],
          civil: [{ type: 'required', message: 'Campo obrigatório.' }],
        },
      },
    ];
    console.log('this.state', this.state);
    //this.formGeneralData[0].builder.controls.estado.value = this.state;
    // this.formGeneralData[0].builder.patchValue({
    // 	estado: this.state || "",
    // 	// cidade: this.city || ""
    // });
  }

  validateAlias(value) {
    this.ENV.loading = true;
    this.httpService
      .unaltorizeRequest('post', 'api/urna/validateAlias', { name: value })
      .then((result: any) => {
        this.ENV.loading = false;
        if (!result.valid) {
          this.errorAlias = true;
          this.openSnackBar(
            'URL já está em uso, por favor digite uma url diferente',
            'OK!'
          );
        } else {
          this.url = result.alias;
          this.errorAlias = false;
          this.openSnackBar('Essa url esta disponível', 'SUCESSO');
        }
      })
      .catch((err) => {
        this.openSnackBar('Esse url já esta sendo utilizada', 'OK!');
        this.ENV.loading = false;
      });
  }
  isValid() {
    let valid = true;
    if (!this.formPersonal) {
      return false;
    }
    const personal = { ...this.formPersonal[0].builder.value };
    if (!this.formPersonal[0].builder.valid) valid = false;
    Object.keys(personal).forEach((el) => {
      if (
        personal[el] === undefined ||
        personal[el] === '' ||
        personal[el] === null ||
        personal[el].length === 0
      ) {
        valid = false;
      }
    });
    return valid;
  }

  selectCity(city) {
    this.city = city.name;
    this.state = city.state[0].initials;
    if (this.state) {
      this.formGeneralData[0].builder.patchValue({
        estado: this.state,
      });
    }
    this.arrCities = [];
    this.busca = '';
  }

  async onSubmit() {
    this.isDisabled = true;
    this.error = false;
    this.success = false;
    this.already = false;

    if (this.errorCpf == true) {
      this.isDisabled = true;
      return this.utils.toast({
        message: 'CPF inválido, por favor digite um CPF válido.',
        action: 'OK!',
        duration: 10000,
      });
    } else if (this.terms == false) {
      this.error = true;
      this.isDisabled = true;
      return this.utils.toast({
        message: 'Por favor, leia e concorde com nossos termos de uso.',
        action: 'OK!',
        duration: 10000,
      });
    }

    if (this.errorAlias) {
      this.error = true;
      this.isDisabled = true;
      return this.utils.toast({
        message: 'URL já em uso, por favor digite outra url.',
        action: 'OK!',
        duration: 10000,
      });
    }

    if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.name.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Nome corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.email.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo E-mail corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formCidade &&
      !this.formCidade[0].builder.controls.municipio.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Município corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.estado.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Estado corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.sexo.valid
    ) {
      return this.utils.toast({
        message: 'O campo Gênero é obrigatório',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.telefone.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Telefone Celular corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.password.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Senha corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.confirmPassword.valid
    ) {
      return this.utils.toast({
        message: 'As senhas não coincidem, por favor revise sua senha',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.cpf.valid
    ) {
      return this.utils.toast({
        message: 'CPF inválido, por favor digite um CPF válido',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formPersonal &&
      !this.formPersonal[0].builder.controls.rg.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo RG corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.cep.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo CEP corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.endereco.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Endereço corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.profissao.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Profissão corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.nacionalidade.valid
    ) {
      return this.utils.toast({
        message: 'O campo Nacionalidade é obrigatório',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.civil.valid
    ) {
      return this.utils.toast({
        message: 'O campo Estado Civil é obrigatório',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.partido.valid
    ) {
      return this.utils.toast({
        message: 'O campo Partido é obrigatório',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.cargo.valid
    ) {
      return this.utils.toast({
        message: 'O campo Cargo é obrigatório',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      !this.formGeneralData[0].builder.controls.urna.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha o campo Urna corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.formGeneralData &&
      (!this.formGeneralData[0].builder.controls.url.valid ||
        this.errorAlias == true)
    ) {
      return this.utils.toast({
        message:
          'Essa URL é inválido ou já está em uso, por favor digite uma url diferente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      (this.formGeneralData && !this.formGeneralData[0].builder.valid) ||
      !this.formPersonal[0].builder.valid
    ) {
      return this.utils.toast({
        message: 'Por favor, preencha corretamente o formulário',
        action: 'Ok',
        duration: 8000,
      });
    }

    if (
      !this.formGeneralData[0].builder.valid ||
      !this.formCidade[0].builder.valid
    ) {
      this.isDisabled = true;
      this.error = true;
      return this.utils.toast({
        message: 'Por favor, preencha todos os campos corretamente',
        action: 'OK!',
        duration: 10000,
      });
    }

    const validPersonal = await this.validatePersonalInfo({
      ...this.formPersonal[0].builder.value,
    });
    if (!validPersonal) {
      return (this.error = true);
    }

    const data = {
      ...this.formPersonal[0].builder.value,
      ...this.formGeneralData[0].builder.value,
      ...this.formCidade[0].builder.value,
      termos: this.terms,
    };
    this.ENV.loading = true;
    // this.error = true;
    delete data.confirmPassword;
    console.log('senduser');
    this.httpService
      .post('api/faleConosco/sendRegisteredEmail', false, data)
      .then((result: any) => {
        console.log('ESSE É O QUE TA CHEGANDO', result);
        this.ENV.loading = false;
        this.success = true;
        this.city = undefined;
        this.state = undefined;
        this.formGeneralData[0].builder.reset();
        this.formCidade[0].builder.reset();
        this.formPersonal[0].builder.reset();
        this.completo = true;
        this.terms = false;
        if (this.stepper) {
          this.stepper.selectedIndex = 0;
        }
        this.url = undefined;
        this.utils.toast({
          message: 'Cadastro realizado com sucesso!',
          action: 'SUCESSO',
          duration: 10000,
        });
        this.auth.setToken(result.token).then(() => {
          this.ENV.logged = true;
          this.router.navigate(['/admin/perfil']);
        });
        this.isDisabled = false;
        //localStorage.setItem('user', JSON.stringify(result));
        //this.ENV.logged = true
        //this.router.navigateByUrl('/entrar')
      })
      .catch((err) => {
        console.log('err: ', err);
        this.userForm.reset();
        // this.error = true;
        this.ENV.loading = false;
        this.already = true;
        this.utils.toast({
          message:
            'Este endereço de e-mail já foi cadastrado, por favor escolha um e-mail diferente.',
          action: 'OK!',
          duration: 10000,
        });
        this.isDisabled = false;
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
  }

  nextPage() {
    if (this.vaquinhaEleitoral.length >= 2) {
      console.log(this.page);
      if (this.page == this.vaquinhaQuantity - 1) this.page = 0;
      else this.page += 1;
    }
  }

  previousPage() {
    if (this.vaquinhaEleitoral.length >= 2) {
      console.log(this.vaquinhaQuantity);
      if (this.page == 0) this.page = this.vaquinhaQuantity - 1;
      else this.page -= 1;
    }
  }

  validatePersonalInfo(form) {
    return new Promise((resolve, reject) => {
      const personal = { ...form };
      Object.keys(personal).forEach((el) => {
        if (
          personal[el] === undefined ||
          personal[el] === '' ||
          personal[el] === null ||
          personal[el].length === 0
        ) {
          return resolve(false);
        }
      });
      return resolve(true);
    }).catch((err) => console.log(err));
  }

  getPicture(candidato: any) {
    if (
      candidato &&
      candidato.userRef &&
      candidato.userRef.media &&
      candidato.userRef.media[0]
    ) {
      const media = candidato.userRef.media[0];
      return media.host + '' + media.full;
    } else {
      return '../../assets/image/no-avatar-width.jpg';
    }
  }

  onKeyCandidato(value: string) {
    this.candidateService.getProfiles({ search: value }).then((res: any) => {
      console.log(res);
      const array = Array.prototype.slice.call(res);
      if (this.values !== '') {
        array.map((e) => {
          if (e.name === this.values) {
            this.candidatos = [e];
            // console.log(this.candidatos);
          }
        });
      } else {
        this.candidatos = res;
      }
    });
  }

  onKeyEstado(value: string) {
    this.candidateService.getProfiles({ search: value }).then((res: any) => {
      // console.log(res);
      const array = Array.prototype.slice.call(res);
      if (this.values !== '') {
        array.map((e) => {
          if (e.state === this.values) {
            this.candidatos = [e];
            // console.log(this.candidatos);
          }
        });
      } else {
        this.candidatos = res;
      }
    });
  }

  candidate(data) {
    console.log('passou', data);
    this.router.navigate(['/candidate'], {
      queryParams: { candidateData: JSON.stringify(data) },
    });
    /* this.router.navigateByUrl('candidate/' + data) */
  }

  scrollTo(el: any) {
    if (this[el]) {
      this[el].nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  // async onSubmit() {
  // 	this.error = false;
  // 	if (this.email === undefined || this.name === undefined ||
  // 		String(this.email).indexOf('@') < 0 || String(this.email).indexOf('.') < 0) {
  // 		this.error = true;
  // 		this.userForm.reset();
  // 		return console.log('INVALIDO');
  // 	}
  // 	const data = {
  // 		email: this.email,
  // 		name: this.name
  // 	};
  // 	this.loading = true;
  // 	this.httpService.unaltorizeRequest('post', 'api/faleConosco/sendRegisteredEmail', data).then((result: any) => {
  // 		this.userForm.reset();
  // 		console.log('RESPONSE: ', result);
  // 		this.loading = false;
  // 		this.success = true;
  // 		this.message = 'Parabéns! Agora você está cadastrado, te avisaremos sobre as novidades.';
  // 		// return this.openSnackBar('Agora você está cadastrado, te avisaremos sobre as novidades.', 'Ok!')
  // 	}).catch(err => {
  // 		console.log('ERROR TRYING TO MAKE POST REQUEST: ', err);
  // 		this.userForm.reset();
  // 		this.loading = false;
  // 		this.success = true;
  // 		this.message = 'Esse email já foi cadastrado, você já está por dentro!';
  // 		// return this.openSnackBar('Esse email já foi cadastrado, você já está por dentro!', 'Ok!');
  // 	});
  // }

  goToUrl(): void {
    console.log('teste');
    this.document.location.href = 'https://www.youtube.com/embed/UO0b-Ly_rAU';
  }

  openDialog() {
    const dialogRef = this.dialog.open(FaleConoscoModalComponent, {
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  goBusca() {
    this.router.navigate(['candidatos']);
  }

  goDuvidas() {
    this.router.navigate(['duvidas']);
  }

  more(candidato) {
    if (candidato && candidato.alias) {
      this.router.navigate([candidato.alias]);
    }
  }

  getNome(candidato) {
    var nome = '';
    if (candidato && candidato.userRef && candidato.userRef.urna) {
      nome = String(candidato.userRef.urna).toLowerCase();
    } else if (candidato && candidato.userRef && candidato.userRef.name) {
      nome = String(candidato.userRef.name).toLowerCase();
    }
    if (nome.length > 16) return nome.slice(0, 16) + '...';
    else return nome;
  }
  getCandidatoTotal(candidatos, page) {
    if (candidatos && candidatos[page]) {
      return candidatos[page].total;
    }
    return 0;
  }

  getVaquinha() {
    this.VaquinhaService.getElectionVaquinha({})
      .then((res) => {
        this.vaquinhaEleitoral = res;
        this.vaquinhaQuantity = this.vaquinhaEleitoral.length;
      })
      .catch((err) => {
        console.log('ERR: ', err);
        throw err;
      });
  }

  getNameVaquinha(vaquinha) {
    if (vaquinha) {
      return vaquinha.title;
    }
    return 0;
  }

  getVaquinhaTotal(vaquinha, page) {
    if (vaquinha && vaquinha[page]) {
      return vaquinha[page].totalCollected;
    }
    return 0;
  }

  getPictureVaquinha(vaquinha: any) {
    if (vaquinha) {
      const media = vaquinha.cover;
      return media.host + media.full;
    } else {
      return '../../assets/image/no-avatar-width.jpg';
    }
  }

  moreVaquinha(vaquinha) {
    this.router.navigate([vaquinha.url], vaquinha);
  }
}
