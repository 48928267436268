<div class="all-content">
<div class="spacer" *ngIf="candidate">
    &nbsp;
</div>
<div class="card-container">
    <div class="card">
        <mat-card class="container-cd">
            <div class="form">
                <img class="logo" src="../../assets/logo/logo.svg"/>
                <h1>Faça agora seu cadastro e comece a arrecadar recursos para a sua campanha!</h1>
                <p class="subText">Não se preocupe com taxas escondidas, no QueroApoiar te mostramos tudo de forma transparente. <br><b>Será cobrado  3,65% em cada doação realizada e uma taxa única de inscrição de R$149,00 Receba doações por cartão de crédito, boleto ou PIX em nosso sistema homologado pelo TSE</b></p>
                <default-form (keyup.enter)="performClick(register)" [forms]="registerForm" [step]="registerFormStep"></default-form>
                <mat-checkbox (keyup.enter)="performClick(register)" class="example-margin checkcadastro" [(ngModel)]="terms">Eu concordo com os <a (click)="openDialog()">termos de uso.</a></mat-checkbox>
                <re-captcha (resolved)="resolved($event)"
                type="image" [siteKey]="siteKey"></re-captcha>
                    <h3><a [routerLink]="['/entrar']">Já possui uma conta? Acessar</a></h3>
                    <br/>
                    <div class="btn-container">
                    <button [disabled]="checkDisabled()" mat-flat-button #register class="btn confirm-button" (click)="onSubmit(registerForm)">registrar</button>


                </div>

            </div>
        </mat-card>
    </div>
</div>
<div class="spacer2" *ngIf="candidate">
    &nbsp;
</div>
</div>
