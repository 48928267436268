// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  loading: false,
  production: true,
  siteKey: '6LcB_WspAAAAALHcWOi6cFJK4c7eN3NX804daUqx',
  // API_URL: 'https://api.queroapoiar.com.br',
  API_URL: 'https://hmlg.queroapoiar.com.br',
  // API_URL: 'http://localhost',
  logged: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
