import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  Inject,
  HostListener,
  Host,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENV } from '../../environments/environment-variables.token';
import { DOCUMENT } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { HttpService } from 'src/providers/http.service';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'questions-home',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit, OnDestroy {
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    public formBuilder: FormBuilder,
    public http: HttpService,
    public utils: UtilsProvider,
    @Inject(ENV) public ENV: any,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public isDisabled = false;
  public contatoForm: any;
  public contatoFormStep: any;
  public assuntos = [
    {
      title: 'Dificuldades Técnicas',
    },
    {
      title: 'Dúvidas Sobre a Maquininha',
    },
    {
      title: 'Financeiro',
    },
    {
      title: 'Parcerias',
    },
    {
      title: 'Indicações',
    },
  ];

  public headerHide: any;
  public showVideo = true;
  public questions = [
    {
      title: 'O QueroApoiar é homologado pelo TSE?',
      answer:
        'Sim. Somos umas das poucas empresas autorizadas pelo TSE a receber doações eleitorais.',
    },
    {
      title: 'Qual o período de arrecadação?',
      answer:
        'A partir do dia 15 de maio de 2022 até o dia das eleições é permitido arrecadar via financiamento coletivo de campanha.',
    },
    {
      title: 'O QueroApoiar possui sistema antifraude?',
      answer:
        'Sim. Todas as transações financeiras feitas por cartão de crédito possuem um rigoroso sistema antifraude para não colocar o seu apoio em risco.',
    },
    {
      title:
        'O QueroApoiar aceita doações via transferência bancária, DOC ou TED?',
      answer: 'Aceitamos PIX, cartão de crédito e boleto.',
    },

    {
      title: 'O QueroApoiar só aceita doações pelo site?',
      answer:
        'Sim, a doação é feita direto pelo site, via PIX, boleto ou cartão de crédito',
    },
    {
      title: 'Qual o diferencial da plataforma?',
      answer:
        'O Quero Apoiar tem as menores taxas, atendimento próximo, segurança, tecnologia de ponta, vários métodos de pagamento, página personalizada com link próprio e várias ferramentas para ajudar a divulgação do seu perfil.',
    },
    {
      title: 'O que preciso para aderir à plataforma?',
      answer:
        'Você precisa ter em mãos alguns dados do candidato: nome completo, CPF, RG e-mail, telefone e uma foto, nome que será usado na candidatura Tudo certo? Pode clicar aqui para realizar o cadastro.',
    },
    {
      title: 'Existe alguma taxa de adesão à plataforma?',
      answer:
        'Para a eleição de 2022 estamos trabalhando com a taxa de inscrição, única, no valor de R$149,00 que será paga via PIX ou cartão de crédito no início do período de arrecadação (15 de maio).',
    },
    {
      title: 'Quais taxas são cobradas e quais o valores?',
      answer:
        'Se a sua doação for feita no cartão de crédito a taxa é de 3,65% por transação, independente do valor da doação. Se optar por doar por boleto, a taxa é de 3,65%. Esse custo é fixo e é independente do valor e só é cobrada se o boleto for compensado.',
    },
    {
      title: 'Qual a taxa para doações feitas por cartão de crédito?',
      answer:
        'A taxa é de 3,65%. Essa taxa é cobrada por transação e é fixa, independente do valor a ser doado.',
    },
    {
      title: 'Qual a taxa para doações feitas por boleto bancário?',
      answer:
        'A taxa é de 3,65%. Essa taxa é fixa, independente do valor de doação. Lembrando que o valor mínimo para doação por boleto é R$60,00.',
    },
    {
      title:
        'É divulgado no site do candidato as taxas administrativas a serem cobradas pelo QueroApoiar?',
      answer: 'Sim, tudo é divulgado seguindo as normas do TSE.',
    },
    {
      title: 'O candidato terá suporte do QueroApoiar?',
      answer:
        'Claro! Temos um time de suporte preparado para todas as dúvidas que surgirem ao longo do processo.',
    },
    {
      title: 'Quem pode receber doação pelo QueroApoiar?',
      answer:
        'Até 15 de agosto de 2022: Candidatos aos cargos de Presidente, Governador, Senador, Deputado Federal, Deputado Estadual e Deputado Distrital de todos as regiões do Brasil. Após 15 de agosto de 2022 até o dia das eleições: Candidatos registrados junto ao TSE aos cargos de Presidente, Governador, Senador, Deputado Federal, Deputado Estadual e Deputado Distrital de todos as regiões do Brasil.',
    },
    {
      title: 'O QueroApoiar emite recibo de doação?',
      answer:
        'Sim, seguindo regras do TSE, é emitido um recibo para o CPF do doador em cada doação.',
    },
    {
      title:
        'Sou candidato e não tenho certeza se irei me candidatar. Como funciona a minha arrecadação?',
      answer:
        'Todo o valor arrecadado de qualquer candidato fica em posse do Quero Apoiar até que o candidato obtenha a conta de campanha e os dados jurídicos (CNPJ) ou se identificamos que o cadastro de candidatura não foi aprovado pelo TSE. Sendo assim todo o valor arrecadado é estornado para o doador, com a retenção da taxa da plataforma, sem danos para o candidato, nem para o doador.',
    },
    {
      title:
        'A arrecadação pode ser feita até o dia da eleição. Considerando que o valor estará disponível para transferência após a eleição, o QueroApoiar poderá depositar recursos na conta da campanha após as eleições?',
      answer:
        'De acordo com o art. 33 da Resolução-TSE nº 23.607/2019, os candidatos podem arrecadar recursos e contrair obrigações até o dia da eleição. A arrecadação via QueroApoiar poderá ser usada após a eleição somente para a quitação de despesas já contraídas e não pagas, essas deverão estar integralmente quitadas até o prazo de entrega da prestação de contas à Justiça Eleitoral.',
    },
    {
      title: 'O que preciso para ser apoiador?',
      answer: 'Simples, basta fazer a doação no site do Quero Apoiar.',
    },
    {
      title: 'É seguro doar?',
      answer:
        'Sim! O QueroApoiar é homologado pelo TSE e todas as transações além de passarem pelo antifraude, são criptografadas para que seus dados bancários e pessoais sejam preservados.',
    },
    {
      title: 'Como posso fazer minha doação?',
      answer:
        'Você pode doar pelo nosso site via cartão de crédito, PIX ou se preferir por boleto bancário.',
    },
    {
      title: 'Existe um valor mínimo e máximo para doação?',
      answer:
        'Você pode doar entre R$20,00 e R$1.064,10 através de financiamento coletivo, por dia, respeitando o limite de 10% (dez por cento) dos rendimentos brutos auferidos pelo doador no ano-calendário anterior à eleição (Lei nº 9.504/1997, art. 23, § 1º). Para cartão de crédito e pix, o mínimo permitido é R$20,00. Para boleto, o mínimo permitido é R$60,00.',
    },
    {
      title: 'É emitido um recibo de doação?',
      answer:
        'Sim, seguindo regras do TSE, é emitido um recibo para o CPF do doador a cada doação.',
    },
    {
      title: 'O doador deve declarar a doação?',
      answer:
        'Sim, sua doação deve ser declarada no seu próximo imposto de renda informando o CNPJ de campanha do candidato na ficha de “Doações a Partidos Políticos, Comitês Financeiros e Candidatos a cargos efetivos”.',
    },
    {
      title: 'Meus dados aparecerão no site do candidato como doador?',
      answer:
        'Sim, conforme o art. 22, II, da Resolução-TSE nº 23.607/2019 seus dados como: nome, CPF e valor doado, serão exibidos na lista de doações do candidato no site público.',
    },
    {
      title:
        'Fiz minha doação durante a pré-campanha, mas o candidato desistiu da candidatura ou não solicitou o registro de candidatura, o que acontece com o valor doado?',
      answer:
        'Na hipótese de o candidato não apresentar o seu pedido de registro de candidatura à Justiça Eleitoral, os recursos arrecadados serão ser devolvidos aos doadores, descontando as taxas.',
    },
    {
      title: 'Há taxa de saque?',
      answer:
        'O primeiro saque é gratuito, após a primeira operação nos cobramos R$5,00 por saque realizado.',
    },
    {
      title:
        'A  plataforma tem integração com o sistema de prestação de contas do STE?',
      answer:
        'A cada saque é gerado um arquivo FCC, que é o formato utilizado pela justiça eleitoral. Basta fazer o upload dele no site do TSE. ',
    },
  ];

  public elements = ['cadastro', 'comofunciona', 'contato'];
  public currentEl = 0;
  private offsetTop = 0;

  @ViewChild('comofunciona', { static: true }) comofunciona: ElementRef;
  @ViewChild('contato', { static: true }) contato: ElementRef;
  @ViewChild('cadastro', { static: true }) cadastro: ElementRef;

  public adminHelpPage: any;
  private prev = 0;

  ngOnInit() {
    this.adminHelpPage = this.router.url.endsWith('ajuda');
    this.setNavbarStatus();
    window.scrollTo(0, 0);
    this.ENV.active = 'help';
    const email = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    );
    const name = new FormControl('', Validators.compose([Validators.required]));
    const assunto = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const message = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    this.contatoFormStep = 0;
    this.contatoForm = [
      {
        fields: [
          {
            name: 'name',
            type: 'text',
            placeholder: 'Nome Completo',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'email',
            type: 'email',
            placeholder: 'E-mail',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'assunto',
            type: 'select',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            placeholder: 'Assunto',
            options: {
              fields: {
                value: 'title',
                text: 'title',
              },
              items: this.assuntos,
            },
            clickCallback: (data) => {},
          },
          {
            name: 'message',
            type: 'textarea',
            rows: 6,
            placeholder: 'Digite a sua mensagem',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          name,
          email,
          assunto,
          message,
        }),
        validation_messages: {
          name: [{ type: 'required', message: 'Preencha o campo nome.' }],
          email: [{ type: 'required', message: 'Preencha o campo e-mail.' }],
          assunto: [{ type: 'required', message: 'Preencha o campo assunto.' }],
          message: [{ type: 'required', message: 'Digite uma mensagem.' }],
        },
      },
    ];
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  setNavbarStatus() {
    if (window.innerWidth > 700) this.ENV.open = true;
    else this.ENV.open = false;
  }

  scrollEvent(event) {
    this.offsetTop = event.target.scrollTop;
    // console.log("SCROLLED: ", event);
  }

  newScroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  scroll(el: any, menu: boolean = false) {
    let element = el;
    const currentEl = String(this.ENV.CURRENT_ELEMENT);
    if (!menu && (!el || el === undefined) && this.ENV.CURRENT_ELEMENT) {
      const idx = this.elements.indexOf(currentEl);
      const nextEl =
        idx === this.elements.length - 1
          ? this.elements[0]
          : this.elements[idx + 1];
      element = nextEl;
    } else if (!this.ENV.CURRENT_ELEMENT) {
      element = this.elements[1];
    }
    setTimeout(() => {
      if (this[element]) {
        this[element].nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }, 0);
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(ev) {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= this.prev) {
      this.headerHide = true;
      this.prev = scrollPosition;
    } else {
      this.headerHide = false;
      this.prev = scrollPosition;
    }
  }

  changeContent(event) {
    const offsetLeft = 0;
    let offsetTop = 0;

    let el = event.srcElement;

    while (el) {
      el = el.parentElement;
      offsetTop += el.offsetTop;
    }
    console.log(offsetTop);

    if (offsetTop <= 700) {
    }
  }

  goToUrl(): void {
    this.document.location.href = '/questions';
  }
  openDialog() {
    const dialogRef = this.dialog.open(FaleConoscoModalComponent, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  goHome() {
    this.router.navigate(['home']);
  }

  clean() {
    this.contatoForm[0].builder.reset();
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
  }

  async onSubmit(event) {
    this.isDisabled = true;
    if (!this.contatoForm[0].builder.valid) {
      this.isDisabled = false;
      return this.utils.toast({
        message: 'Por favor, preencha corretamente o formulário.',
        action: 'Ok',
        duration: 10000,
      });
    }
    const data = { ...event[0].builder.value };

    try {
      await this.http.post('api/faleConosco/sendContactEmail', false, data);
      this.utils.toast({
        message:
          'Mensagem enviada com sucesso! Entraremos em contato em breve.',
        action: 'Ok',
        duration: 10000,
      });
      //this.contatoForm[0].builder.reset();
      this.isDisabled = false;
    } catch (err) {
      console.log(err);
      this.utils.toast({
        message:
          'Não foi possível enviar sua mensagem, por favor verifique seus dados ou tente novamente mais tarde.',
        action: 'Ok',
        duration: 10000,
      });
      this.isDisabled = false;
    }
  }
}
