import { ModalCpfFormComponent } from 'src/app/modal-cpf-form/modal-cpf-form.component';
import { ModalPaidFormComponent } from './../modal-paid-form/modal-paid-form.component';
import { ModalPaidComponent } from './../modal-paid/modal-paid.component';
import { UserService } from 'src/providers/user.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ENV } from 'src/environments/environment-variables.token';
import { HttpService } from 'src/providers/http.service';
import { ScrollService } from 'src/providers/scroll.service';
import { Subscription } from 'rxjs';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { CandidateService } from '../../providers/candidate.service';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/providers/auth.service';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { UtilsProvider } from 'src/commons/utils';
import { MatButton } from '@angular/material/button';
import { MatDrawer } from '@angular/material/sidenav';



@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
	// animations: [
	// 	trigger('fade', [
	// 		transition('void => *', [style({ opacity: 0 }), animate('800ms', style({ opacity: 1 }))]),
	// 		transition('* => void', [style({ opacity: 1 }), animate('800ms', style({ opacity: 0 }))]),
	// 	])
	// ]
})
export class AdminComponent implements OnInit {
	title = 'Quero Apoiar - Admin';
	public navOpen = true;

	@ViewChild('depoimentos') depoimentos: ElementRef;
	@ViewChild('produtos') produtos: ElementRef;
	@ViewChild('drawer') public drawer: MatDrawer;
	constructor(
		private httpClient: HttpClient,
		private userProvider: UserService,
		private router: Router,
		private scrollService: ScrollService,
		private candidateService: CandidateService,
		private formBuilder: FormBuilder,
		private httpService: HttpService,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		public authProvider: AuthService,
		private auth: AuthService,
		public utils: UtilsProvider,
		private metaTagService: Meta,
		private _snackBar: MatSnackBar,
		public dialog: MatDialog,
		@Inject(DOCUMENT) private document: Document,
		@Inject(ENV) public ENV: any
	) { }

	ngOnInit() {

		
		// this.userProvider.checkPaid().then((res: any) =>{
		// 	res.paid ? console.log("Usuário pagou a taxa")
		// 	 : this.openDialog()
		// }).catch((err: any)=>{
		// 	console.log(err)
		// })

		this.setNavbarStatus()
		window.scrollTo(0,0)

		console.log("aaaaaaaaaaaaaac", this.activatedRoute.children)
		const self = this;
		if (!localStorage.getItem('user')) {
			this.router.navigate(['/entrar']);
		}
		this.ENV.admin = true;
		this.metaTagService.updateTag(
			{ name: 'description', content: 'Bem vindo ao Quero Apoiar' }
		);
	}

	ngOnDestroy() {
        console.log("teste header")
        this.ENV.admin = false;
	}

	setNavbarStatus() {
		if (window.innerWidth > 700) this.ENV.open = true
		else this.ENV.open = false
	}

	setActive(active: any) {
		this.ENV.active = active
	}

	logout() {
		this.ENV.logged = false;
		this.ENV.admin = false;
		this.ENV.active = "";
        localStorage.clear();
        this.router.navigate(["/"]);
    }

	openDialog() {
		const confirmDialog = this.dialog.open(ModalPaidComponent, {
			disableClose: true,
			data: {
				title: 'Bem-vindo (a) ao QueroApoiar! ',
				message: '<p><b>Perfil criado com sucesso!</b> <br>Para editar seus dados e começar a utilizar a plataforma, pague a taxa única de inscrição no valor de R$149,00. QueroApoiar, seu apoio começa aqui! </p>'
			}
		});
		confirmDialog.afterClosed().subscribe(result => {
			const _user = JSON.parse(localStorage.getItem('user'));
			console.log(_user.user.cpf)
			if(_user.user.cpf){
				const dialogRef = this.dialog.open(ModalPaidFormComponent, {
					disableClose: true,
					data: {
					amount: 149,
					product_code: "Subscription"
					}
				});
				// dialogRef.afterClosed().subscribe(result => {
				// 	this.userProvider.checkPaid().then((res: any) =>{

				// 	}).catch((err: any)=>{
				// 		console.log(err)
				// 	})
				// });
			} else {
				const dialogRef = this.dialog.open(ModalCpfFormComponent, {
					disableClose: true,
				});
				dialogRef.afterClosed().subscribe(result => {
					this.authProvider.me();
				 });
			}
			// if (result === 'false') { console.log("false") }
			// if(result === 'true') { console.log("true"), this.openDialogMAquininha() }
		});
	}
	
}
