import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CandidateService } from 'src/providers/candidate.service';
import { HttpService } from 'src/providers/http.service';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Title, Meta } from '@angular/platform-browser';
import { UrnaService } from '../../providers/urna.service';
import { UtilsProvider } from 'src/commons/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import * as moment from 'moment';
import { VaquinhaService } from 'src/providers/vaquinha.service';
@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss'],
})
export class CandidateComponent implements OnInit {
  title: any;

  public dataRouter: any;

  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faInstagram = faInstagram;
  faWhatsapp = faWhatsapp;
  fotoCandidato: any;
  candidatos: any;
  id = this.activatedRoute.snapshot.paramMap.get('entity');
  donations = [];
  public candidato: any;
  userDataBios: any;
  anotherValue = false;
  public expandFlags = false;
  public imageUrl = '../../assets/no-avatar.png';
  public userDataSocialMedia: any;
  public donateValue: any;
  userMedias = {};
  public donationValue: string;
  public userNotFound = false;
  public flags = [];
  public candidateName: any;
  public candidateUrna: any;
  public candidatePartido: any;
  public candidateCargo: any;
  public candidateCity: any;
  public candidateState: any;
  public candidateSexo: any;
  public url: any;
  public values: any;
  public thisUrl: any;
  public videoUrl: any;
  public coverImage: any;
  public special = false;
  public numDonations = 0;
  public valueDonations = 0;
  public lat = -15.7801;
  public long = -47.9292;
  public stringCandidato: any;
  public valuesDonations = [10, 20, 50, 100];
  public checkValueInput: any = 0;
  public data: any;
  // public darkStyle = [
  // 	{
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  color: '#1d2c4d'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#8ec3b9'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  elementType: 'labels.text.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#1a3646'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'administrative',
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'administrative.country',
  // 	  elementType: 'geometry.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#4b6878'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'administrative.land_parcel',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'administrative.land_parcel',
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#64779e'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'administrative.neighborhood',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'administrative.province',
  // 	  elementType: 'geometry.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#4b6878'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'landscape.man_made',
  // 	  elementType: 'geometry.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#334e87'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'landscape.natural',
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  color: '#023e58'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'poi',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'poi',
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  color: '#283d6a'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'poi',
  // 	  elementType: 'labels.text',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'poi',
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#6f9ba5'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'poi',
  // 	  elementType: 'labels.text.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#1d2c4d'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'poi.park',
  // 	  elementType: 'geometry.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#023e58'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'poi.park',
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#3C7680'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road',
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  color: '#304a7d'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road',
  // 	  elementType: 'labels',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road',
  // 	  elementType: 'labels.icon',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road',
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#98a5be'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road',
  // 	  elementType: 'labels.text.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#1d2c4d'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road.highway',
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  color: '#2c6675'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road.highway',
  // 	  elementType: 'geometry.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#255763'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road.highway',
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#b0d5ce'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'road.highway',
  // 	  elementType: 'labels.text.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#023e58'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'transit',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'transit',
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#98a5be'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'transit',
  // 	  elementType: 'labels.text.stroke',
  // 	  stylers: [
  // 		{
  // 		  color: '#1d2c4d'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'transit.line',
  // 	  elementType: 'geometry.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#283d6a'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'transit.station',
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  color: '#3a4762'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'water',
  // 	  elementType: 'geometry',
  // 	  stylers: [
  // 		{
  // 		  color: '#0e1626'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'water',
  // 	  elementType: 'labels.text',
  // 	  stylers: [
  // 		{
  // 		  visibility: 'off'
  // 		}
  // 	  ]
  // 	},
  // 	{
  // 	  featureType: 'water',
  // 	  elementType: 'labels.text.fill',
  // 	  stylers: [
  // 		{
  // 		  color: '#4e6d70'
  // 		}
  // 	  ]
  // 	}
  //   ];

  constructor(
    private httpClient: HttpClient,
    private http: HttpService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private candidateService: CandidateService,
    private titleService: Title,
    private metaTagService: Meta,
    private urnaServie: UrnaService,
    private utils: UtilsProvider,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private vaquinhaService: VaquinhaService
  ) {}

  async getVaquinha() {
    const [errVaquinhaEleitoral, vaquinhaEleitoral] = await this.utils.tryCatch(
      this.vaquinhaService.getElectionVaquinha({
        skip: 0,
        limit: 1,
        year: new Date().getFullYear(),
        url: this.router.url.split('/')[1],
      })
    );

    if (errVaquinhaEleitoral)
      console.log('erro ao buscar ', errVaquinhaEleitoral);

    if (vaquinhaEleitoral.length != 0)
      return (this.data = vaquinhaEleitoral[0]);

    const [err, vaquinha] = await this.utils.tryCatch(
      this.vaquinhaService.getVaquinha({
        skip: 0,
        limit: 1,
        url: this.router.url.split('/')[1],
      })
    );
    if (err) console.log('erro ao buscar ', err);

    this.data = vaquinha[0];
    // console.log("DATAA", this.data);
  }

  ngOnInit() {
    console.log('activated route -- exec', this.data);
    this.getVaquinha();
    console.log('activated this.data carregado');
    // this.vaquinhaService
    //   .getElectionVaquinha({
    //     skip: 0,
    //     limit: 1,
    //     year: new Date().getFullYear(),
    //     url: this.router.url.split('/')[1],
    //   })
    //   .then((res) => {
    //     this.data = res;
    //   });

    // this.thisUrl = window.location.href;
    // /* this.candidateService.getProfiles({all: true}).then(res => { */
    // this.activatedRoute.paramMap.subscribe(async (_params: any) => {
    //   console.log('_params', _params);
    //   this.url = _params.params.entity;
    //   this.thisUrl = 'https://www.queroapoiar.com.br/' + this.url;
    //   // if (this.url === 'fredluz') {
    //   // 	this.valuesDonations = [30, 300, 600, 1000];
    //   //  }
    //   // if (this.url === 'mc') {
    //   // 	this.valuesDonations = [100, 300, 500, 1000];
    //   //  }
    //   const [errGetProfile, res] = await this.utils.tryCatch(
    //     this.urnaServie.getProfile({ alias: 'beguido' })
    //   );
    //   if (errGetProfile || !res.userRef) {
    //     // this.userNotFound = true;
    //     // setTimeout(() => {
    //     //   this.router.navigate(['/']);
    //     // }, 5000);
    //   }
    //   // return;
    //   this.candidato = res;
    //   console.log('USER PROFILE: ', res.userRef);
    //   if (
    //     res &&
    //     res.userRef &&
    //     moment(res.userRef.yearLimit).isBefore('2022-01-01T00:00:00.000+0000')
    //   ) {
    //     // setTimeout(() => {
    //     //   this.router.navigate(['/']);
    //     // }, 5000);
    //   }
    //   // if (!res || res.userRef || res.userRef.name) { this.router.navigate(['/']); }
    //   const candidateUrl = _params.params.entity;
    //   this.candidateName = res.userRef.name
    //     ? res.userRef.name
    //     : res.userRef.urna;
    //   this.candidateUrna = res.userRef.urna
    //     ? res.userRef.urna
    //     : res.userRef.name;
    //   this.candidatePartido = res.userRef.partido;
    //   this.candidateCargo = res.userRef.cargo;
    //   this.candidateCity = res.userRef.municipio;
    //   this.candidateState = res.userRef.estado;
    //   this.candidateSexo = res.userRef.sexo;

    //   // this.getCoordinates();
    //   this.getGenero();

    //   const [errValue, value] = await this.utils.tryCatch(
    //     this.candidateService.getDonationsValue({
    //       user: this.candidato.userRef._id,
    //     })
    //   );
    //   if (errValue) {
    //     console.log('Não foi possível resgatar os valores das doações');
    //   }

    //   console.log('VALUE: ', value);
    //   this.numDonations = value.data.num > 0 ? value.data.num : 0;
    //   const valor = value.data.value > 0 ? value.data.value : 0;
    //   this.valueDonations = valor;
    //   this.metaTagService.updateTag({
    //     property: 'og:url',
    //     content: 'https://queroapoiar.com.br/' + candidateUrl,
    //   });
    //   this.metaTagService.updateTag({
    //     property: 'og:title',
    //     content: 'Vamos apoiar ' + this.candidateUrna,
    //   });
    //   this.metaTagService.updateTag({
    //     property: 'og:description',
    //     content: 'QueroApoiar - Seu apoio começa aqui!',
    //   });
    //   this.metaTagService.updateTag({
    //     property: 'og:image',
    //     content: this.imageUrl,
    //   });
    //   this.metaTagService.updateTag({
    //     name: 'twitter:title',
    //     content: 'Vamos apoiar ' + this.candidateUrna,
    //   });
    //   this.metaTagService.updateTag({
    //     name: 'twitter:description',
    //     content: 'QueroApoiar - Seu apoio começa aqui!',
    //   });
    //   this.metaTagService.updateTag({
    //     name: 'twitter:image',
    //     content: this.imageUrl,
    //   });

    //   console.log('CANDIDATE-PROFILE', res.candidateProfile);
    //   if (
    //     res &&
    //     res.userRef &&
    //     res.userRef.media &&
    //     res.userRef.media.length > 0
    //   ) {
    //     const media = res.userRef.media[0];
    //     this.imageUrl = media.host + media.full;
    //   }
    //   if (
    //     res &&
    //     res.candidateProfile &&
    //     res.candidateProfile.values &&
    //     res.candidateProfile.values[0]
    //   ) {
    //     this.valuesDonations = res.candidateProfile.values;
    //   }
    //   if (res && res.candidateProfile && res.candidateProfile.video) {
    //     this.videoUrl = this.treatVideoUrl(res.candidateProfile.video);
    //   }
    //   if (res && res.candidateProfile && res.candidateProfile.cover) {
    //     const cover = res.candidateProfile.cover;
    //     this.coverImage = cover.host + cover.full;
    //     console.log('COVER-IMAGE', this.coverImage);
    //   }
    //   if (res && res.candidateProfile && res.candidateProfile.bios) {
    //     this.userDataBios = res.candidateProfile.bios;
    //   }
    //   if (res && res.candidateProfile && res.candidateProfile.socialMedias) {
    //     this.userDataSocialMedia = res.candidateProfile.socialMedias;

    //     if (this.userDataSocialMedia.facebook) {
    //       const facebook = String(this.userDataSocialMedia.facebook);
    //       this.userDataSocialMedia.facebook =
    //         facebook.indexOf('http') !== -1
    //           ? facebook
    //           : 'https://facebook.com/' + facebook;
    //     }
    //     if (this.userDataSocialMedia.twitter) {
    //       const twitter = String(this.userDataSocialMedia.twitter);
    //       this.userDataSocialMedia.twitter =
    //         twitter.indexOf('http') !== -1
    //           ? twitter
    //           : 'https://twitter.com/' + twitter;
    //     }
    //     if (this.userDataSocialMedia.instagram) {
    //       const instagram = String(this.userDataSocialMedia.instagram);
    //       this.userDataSocialMedia.instagram =
    //         instagram.indexOf('http') !== -1
    //           ? instagram
    //           : 'https://instagram.com/' + instagram;
    //     }
    //     if (this.userDataSocialMedia.youtube) {
    //       const youtube = String(this.userDataSocialMedia.youtube);
    //       this.userDataSocialMedia.youtube =
    //         youtube.indexOf('http') !== -1
    //           ? youtube
    //           : 'https://youtube.com/' + youtube;
    //     }
    //   }
    //   if (
    //     res &&
    //     res.candidateProfile &&
    //     res.candidateProfile.flags &&
    //     res.candidateProfile.flags.length > 0
    //   ) {
    //     this.flags = res.candidateProfile.flags;
    //   }
    this.metaTagService.updateTag({
      property: 'og:url',
      content: 'https://queroapoiar.com.br/' + this.router.url.split('/')[1],
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: 'Quero apoiar: ' + this.candidateUrna,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: 'Visite o site para apoiar ' + this.candidateUrna,
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: this.imageUrl,
    });
    this.metaTagService.updateTag({
      property: 'og:image:secure_url',
      content: this.imageUrl,
    });
    this.metaTagService.updateTag({
      name: 'twitter:title',
      content: 'Quero apoiar: ' + this.candidateUrna,
    });
    this.metaTagService.updateTag({
      name: 'twitter:description',
      content: 'Visite o site para apoiar ' + this.candidateUrna,
    });
    this.metaTagService.updateTag({
      name: 'twitter:image',
      content: this.imageUrl,
    });
    // });
  }

  setDonateValue(ev) {
    console.log('value', ev.target.value);
    this.donateValue = ev.target.value;
  }

  getGenero() {
    if (this.candidateSexo === 'M') {
      this.stringCandidato = 'Candidato';
      this.candidateCargo = String(this.candidateCargo);
    } else if (this.candidateSexo === 'F') {
      this.stringCandidato = 'Candidata';
      if (this.candidateCargo === 'Deputado Estadual') {
        this.candidateCargo = String(this.candidateCargo).replace('o', 'a');
      } else if (this.candidateCargo === 'Deputado Federal') {
        this.candidateCargo = String(this.candidateCargo).replace('o', 'a');
      } else if (this.candidateCargo === 'Deputado Distrial') {
        this.candidateCargo = String(this.candidateCargo).replace('o', 'a');
      } else if (this.candidateCargo === 'Governador') {
        this.candidateCargo = String(this.candidateCargo) + 'a';
      } else if (this.candidateCargo === 'Senador') {
        this.candidateCargo = String(this.candidateCargo) + 'a';
      }
    } else {
      this.stringCandidato = 'Candidato(a)';
      this.candidateCargo = String(this.candidateCargo) + '(a)';
    }
    console.log('GENERO', this.candidateSexo);
  }

  getCoordinates() {
    const state = this.candidateState;
    const city = this.candidateCity;
    this.httpClient
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${city},+${state}&key=AIzaSyCA7-Z1-aAW-fBiE8-lXk31JvXO1FOJVLI`
      )
      .subscribe((res) => {
        const refs = JSON.parse(JSON.stringify(res));
        this.lat = refs.results[0].geometry.location.lat;
        this.long = refs.results[0].geometry.location.lng;
        console.log(this.lat, this.long);
      });
  }

  treatVideoUrl(url) {
    const video = url.replace('watch?v=', 'embed/');
    return this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  public copyToClipboard = () => {
    const url = 'https://www.queroapoiar.com.br/' + this.url;
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', url);
      e.preventDefault();
      // document.removeEventListener('copy', );
    });
    document.execCommand('copy');
    return this.utils.toast({
      message: 'URL copiada com sucesso!',
      action: 'Ok!',
    });
  };

  goDonation() {
    if (moment().isBefore(moment('2022-05-15T00:00:00.854+0000'))) {
      const dialogRef = this.dialog.open(ModalDefaultComponent, {
        autoFocus: false,
        data: {
          title: 'Doações só estarão liberadas a partir do dia 15/5/2022!',
          message: '',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});

      return;
    } else {
      console.log('this.donateValue', this.donateValue);

      if (this.donateValue && Number(this.donateValue) >= 10) {
        console.log('this.candidato', this.candidato);

        const cd = JSON.stringify({
          name: this.data.title,
          id: this.data._id,
          url: this.data.url,
          message: this.data.description,
          urna: this.data.title,
          image: this.data.cover.host + this.data.cover.full,
          paymentType: this.data.paymentTypes,
          typeRef: this.data.typeRef,
        });

        console.log('cd', cd);

        this.router.navigate(['/pagamentos'], {
          queryParams: {
            vaquinha: cd,
            valor: this.donateValue,
          },
        });
      } else {
        this.utils.toast({
          message: 'O valor doado deve ser maior que R$20,00',
        });
      }
    }
  }
  getDonationsList(id) {
    this.router.navigate(['/doacoes'], {
      queryParams: {
        candidato: JSON.stringify({
          _id: id,
        }),
      },
    });
  }

  isOwner() {
    const _user = JSON.parse(localStorage.getItem('user'));
    if (_user) {
      if (
        this.candidato &&
        this.candidato.userRef &&
        _user.user &&
        this.candidato.userRef._id === _user.user._id
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  anotherValueDonation() {
    this.anotherValue = false;
    console.log(this.donationValue);
    return this.utils.toast({
      message:
        'Sua doação foi enviada com sucesso. Obrigada por apoiar o seu candidato',
      action: 'Ok',
      duration: 8000,
    });
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    this.utils.toast({ message: 'Url copiada' });
    document.body.removeChild(selBox);
  }
  onKeyup(event) {
    this.checkValueInput =
      event.target.value > 1064 || event.target.value < 20 ? true : false;
    return this.checkValueInput;
  }
}
