<div class="all-content">

    <mat-card class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-xs-12">
        <div mat-card-image *ngIf="coverImage" class="card-image center-cropped"
            style="background-image: url({{coverImage}});"></div>
        <agm-map mat-card-image *ngIf="!coverImage" [latitude]="lat"
            [longitude]="long" [zoom]="12" [styles]="darkStyle" [disableDefaultUI]="true"></agm-map>
        <div class="info-container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="info">
                        <div class="avatar">
                            <div class="avatar-img center-cropped" style="background-image: url({{vaquinha.image}});"></div>
                            <div>
                                <h3>Doar R${{valor.replace(".",",")}} para</h3>
                                 <h1 *ngIf="vaquinha.urna">{{ candidateName }}</h1>
                            </div>

                        </div>



                        <mat-horizontal-stepper [linear]="true" #stepper *ngIf="!completed && !debitComplete">
                            <mat-step [completed]="isValidAddress() && CepValido">
                                <ng-template matStepLabel>Endereço de cobrança</ng-template>
                                <default-form (keyup.enter)="performClick(avancarEnd)" [forms]="formAddress" [step]="0" *ngIf="formAddress"></default-form>
                                <br>
                                <div class="btn-container">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <button #avancarEnd mat-flat-button matStepperNext (click)="DadosIncorrect()" class="confirm-button full">
                                                avançar</button>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step [completed]="formPersonal[0].builder">
                                <ng-template matStepLabel>Dados pessoais</ng-template>
                                <default-form [forms]="formPersonal" (keyup.enter)="performClick(avancarDados)" [step]="0" *ngIf="formPersonal"></default-form>
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-checkbox [checked]="terms" (change)="toggle($event)">Estou de acordo com os <span class="link" (click)="OpenTerms()">termos de uso</span> da plataforma</mat-checkbox>
                                </mat-form-field>
                                <br>
                                <br>
                                <div class="btn-container">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <button mat-flat-button matStepperPrevious class="confirm-button full grey">
                                                voltar</button>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 align-right">
                                            <!-- <button #avancarDados mat-flat-button matStepperNext class="confirm-button full">
                                                Avançar</button> -->
                                            <button #avancarDados mat-flat-button (click)="isValidPersonal(stepper)" class="confirm-button full">avançar</button>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step>
                                <ng-template matStepLabel>Escolha a forma como deseja doar</ng-template>
                                <div class="full p20t">
                                    <mat-accordion>
                                        <mat-expansion-panel [disabled]="creditCard">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title class="questions">
                                                    Cartão de Crédito
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <mat-panel-description>
                                                <div class="full">
                                                    <br><br>
                                                    <default-form (keyup.enter)="performClick(enviarCredito)" [forms]="formCredito" [step]="0"></default-form>
                                                    <button mat-flat-button  [disabled]="checkDisabled()" #enviarCredito class="confirm-button full"
                                                        (click)="submitCredit()">
                                                        Enviar</button>
                                                </div>
                                            </mat-panel-description>
                                        </mat-expansion-panel>
                                        <!-- <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title class="questions">
                                                    Cartão de Débito
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <mat-panel-description>
                                                <div class="full">
                                                    <default-form (keyup.enter)="performClick(enviarDebito)" [forms]="formDebito" [step]="0"></default-form>
                                                    <div class="info">
                                                        <div class="cards">
                                                            Bandeiras Aceitas:
                                                            <div class="flags">
                                                                <img class="icon"
                                                                    src="../../assets/payment/mastercard.png">
                                                                <img class="icon" src="../../assets/payment/visa.png">
                                                            </div>
                                                        </div>
                                                        <div class="banks">
                                                            Bancos Aceitos:
                                                            <div class="flags">
                                                                <img class="icon-big" src="../../assets/payment/bb.png">
                                                                <img class="icon-big"
                                                                    src="../../assets/payment/santander.png">
                                                                <img class="icon" src="../../assets/payment/itau.png">
                                                                <img class="icon"
                                                                    src="../../assets/payment/bradesco-logo-novo-2018.png">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button mat-flat-button  [disabled]="checkDisabled()" #enviarDebito class="confirm-button full"
                                                        (click)="submitDebit()">
                                                        Enviar</button>
                                                </div>
                                            </mat-panel-description>
                                        </mat-expansion-panel> -->
                                        <mat-expansion-panel [disabled]="newBoleto">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title class="questions">
                                                    Boleto Bancário
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <mat-panel-description>
                                                <div class="full">
                                                    <div>
                                                        <default-form (keyup.enter)="performClick(enviarBoleto)" [forms]="formBoleto" [step]="0"></default-form>
                                                        <button mat-flat-button [disabled]="checkDisabled()" #enviarBoleto class="confirm-button full"
                                                            (click)="submitBankSlip()">
                                                            Gerar Boleto
                                                        </button>
                                                    </div>
                                                </div>
                                            </mat-panel-description>
                                        </mat-expansion-panel>

                                        <mat-expansion-panel [disabled]="pix">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title class="questions">
                                                    PIX
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <mat-panel-description>
                                                <div class="full">
                                                    <div>
                                                        <default-form (keyup.enter)="performClick(enviarPIX)" [forms]="formBoleto" [step]="0"></default-form>
                                                        <button mat-flat-button [disabled]="checkDisabled()" #enviarPIX class="confirm-button full"
                                                            (click)="submitPIX()">
                                                            Gerar PIX
                                                        </button>
                                                    </div>
                                                </div>
                                            </mat-panel-description>

                                        </mat-expansion-panel>
                                        <br>
                                    </mat-accordion>
                                </div>
                                <div class="btn-container" *ngIf="!completed">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <button mat-flat-button matStepperPrevious class="confirm-button full grey">
                                                voltar</button>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                        </div>
                                    </div>
                                </div>

                            </mat-step>

                        </mat-horizontal-stepper>
                        <div class="btn-container" *ngIf="completed">
                            <div *ngIf="!paymentError && !BankSlipUrl && !pixCode"  class="success">
                                <h3>Pagamento em processamento, aguarde a confirmação por e-mail!</h3>
                            </div>
                            <div *ngIf="!paymentError" [innerHtml]="vaquinha.message"></div>
                            <div *ngIf="paymentError">
                                <p><b>Erro ao realizar o pagamento</b></p><br>
                                <p>{{paymentError}}</p>
                            </div>
                            <br><br>
                            <div *ngIf="barcode">
                                <p>
                                    Código de barras:<br> <strong>{{barcode}}</strong>
                                </p>
                            </div>

                            <div *ngIf="BankSlipUrl && !pixCode" class="success">
                                <h3>Clique <u><a href="{{BankSlipUrl}}" target="_blank">aqui</a></u> para visualizar o boleto
                                </h3>
                            </div>
                            <div *ngIf="BankSlipUrl && !pixCode" class="voltar">
                                <label>ou</label><br>
                                <div>
                                    <a (click)="cancel()">
                                        voltar para a página de doações</a>
                                </div>
                            </div>
                            <br>
                            <div *ngIf="!BankSlipUrl && !pixCode" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <button mat-flat-button (click)="cancel()" class="confirm-button full">
                                        voltar para a página de doações</button>
                                </div>
                            </div>

                            <br><br>
                            <div *ngIf="pixSVG" >
                                <div class="row">
                                    <div class="col-lg-12 col-md-12" style="text-align: center;">
                                        <!-- <object type="image/svg+xml" [data]="pixSVG" width="300" height="300"></object> -->
                                        <img [src]="pixSVG" alt="QRcode" width="300" height="350">
                                    </div>
                                </div>
                                <br>
                                <i *ngIf="copied" class="fa fa-solid fa-check greenIcon"> Copiado com sucesso</i>
                              <div (click)="copyMessage(pixCode)" class="success">
                                  PIX Copia e Cola:<br> <strong>{{ pixCode }}</strong>
                              </div>
                              <p class="text"> Para pagar, escaneie o QR Code acima. Ou você pode, também, copiar o código do PIX e colar no aplicativo da sua instituição financeira.</p>
                              <button mat-flat-button (click)="copyMessage(pixCode)" class="confirm-button full download-button">
                                  Copiar PIX</button>
                            </div>
                            <br>
                            <!-- <div *ngIf="pixSVG">
                                <div class="my-icon" aria-label="My icon" [inlineSVG]="pixSVG"></div>
                            </div> -->

                        </div>
                        <!-- <h4>Preencha os dados de endereço de cobrança</h4>
                        <default-form [forms]="formAddress" [step]="0"></default-form>
                        <h4>Escolha a forma como deseja doar</h4> -->

                    </div>
                </div>
                <div
                    class="col-lg-5 col-lg-offset-1 last-lg col-md-5 last-md col-md-offset-1 col-sm-12 first-sm col-xs-12 first-xs center-cropped negative-margin">
                    <!-- img mat-card-image style="margin-bottom: 0;" src="../../assets/12232.jpg"-->
                </div>
            </div>
        </div>
    </mat-card>
</div>
