<div class="home-content">
    <div class="content" fxFlex="50" wmAnimate="landing" speed="slow" aos>
        <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
            <div class="row component-container">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="box">
                        <mat-card class="main-card">
                            <div #cadastro class="header">
                               <img class="icon iconqa" src="../../assets/image/logo.svg" />
                                <h1>
                                    <!-- Em <span>{{daysLeft}} dias</span> sua campanha poderá receber doações!<br />
                                    E nós vamos te ajudar! -->
                                    Receba agora doações para sua campanha<span> com as melhores taxas e o melhor custo
                                        do Brasil!</span>
                                </h1>
                            </div>
                            
                            <p>Cadastre-se agora! <strong>Faça parte!</strong></p>
                            <mat-card-actions>
                                <div class="btn-container">
                                    <button mat-flat-button class="confirm-button" [routerLink]="'/cadastrar'">
                                        <i class="fa  fa-thumbs-up"></i> Cadastre-se e comece a receber</button>
                                </div>
                            </mat-card-actions>
                            <!-- ngx-loading [show]="loading" [config]="" [template]="customLoadingTemplate"></ngx-loading -->
                        </mat-card>
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-xs-12">
                                <mat-card class="second-card">
                                    <img class="icon" src="../../assets/image/maquininha.png" />
                                    <h1 class="tax-title">
                                        Melhores taxas
                                    </h1>
                                    <p class="sub-sub-giga">
                                        Melhor custo do Brasil, taxa única e sem mensalidade<br>
                                    </p>
                                    <p class="giga"> 3,65%</p>
                                    <p class="sub-giga">
                                        <strong>Crédito, Boleto e PIX</strong><br>
                                        Taxa de cadastro única de R$149,00
                                    </p>
                                    <!-- <p class="sub-giga">
                                        <strong>Boleto</strong><br>
                                         3,65% + R$ 2,00 por transação
                                    </p>
     -->
                                    <button class="redirect-button" mat-flat-button (click)="goDuvidas()">dúvidas?</button>
                                    <!-- <p>
                                        Estamos comprometidos em ter as <strong>melhores taxas.</strong>
                                        <br />Entendemos que as campanhas têm de ser mais econômicas e contar com o apoio e
                                        financiamento
                                    </p> -->
                                </mat-card>
                            </div>
                            
                            
                            <div #pagina class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <mat-card class="image-card">
                                    <img mat-card-image style="margin-bottom: 0;"
                                        src="../../assets/image/usepix.jpg" />
                                    <div class="text page-person">
                                        <h1>Receba doações e faça saques <span class="blue">com pix!</span></h1>
                                        <p>Divulgue para todos sua página personalizada, conte sua história e receba doações.</p>
                                    </div>
                                    <button class="redirect-button" mat-flat-button [routerLink]="'/cadastrar'">faça parte</button>
                                </mat-card>
                            </div>
                            <div #ajuda class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <!-- <mat-card class="second-card">
                                    <img class="icon" src="../../assets/image/balao2.png" />
                                    <h1>
                                        Precisa de ajuda?
                                    </h1>
                                    <p>Suporte não é só atendimento. Mas é também proximidade, comprometimento e
                                        parceria.
                                    </p>
                                    <button class="redirect-button" mat-flat-button [routerLink]="'/contato'">Fale
                                        Conosco</button>
                                </mat-card> -->
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div #duvidas class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="box">
                    <mat-card class="image-card">
                        <div class="row" *ngIf="!showVideo">
                            <!-- <div class="col-md-6 col-sm-12 col-xs-12 text video-container">
                                <h1>
                                    Dúvidas?
                                </h1>
                                <p>
                                    Assista nosso vídeo sobre financiamento coletivo de campanhas apresentando o Quero Apoiar.
                                </p>
                                <img class="img-duvidas" mat-card-image src="../../assets/image/home.gif" /> -->
                            <!-- <button mat-flat-button class="confirm-button" (click)="goToUrl()"><i
                                        class="fa fa-play-circle"></i>Assista agora!</button>
                            </div> -->
                            <!-- <div class="col-md-6 col-xs-12 video-img">
                                <img class="img-duvidas" src="../../assets/image/home.gif" />
                            </div> *ngIf="showVideo" -->
                        </div>                        
                            <iframe width="100%" height="310" src="https://www.youtube.com/embed/UO0b-Ly_rAU"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </mat-card>
                    <!-- <div class="row"> -->
                        <!-- <div class="col-md-6 col-sm-12 col-xs-12">
                        <mat-card class="image-card">
                                                                <img mat-card-image style="margin-bottom: 0;"
                                        src="../../assets/image/usemaquina.jpg" />
                            <h1>
                                Receba com maquininha!
                            </h1>
                            <p>Assine nossa solução para doações presenciais, <b>com os melhores valores e taxas do mercado</b>, e conte com a comodidade das maquininhas.
                            </p>
                            <button class="redirect-button" mat-flat-button [routerLink]="'/contato'">saiba mais</button>
                        </mat-card>
                        </div> -->
                        <!-- <div class="col-md-6 col-sm-12 col-xs-12">
                        <mat-card class="second-card">
                            <img class="icon" src="../../assets/image/balao2.png" />
                            <h1>
                                Precisa de ajuda?
                            </h1>
                            <p>Estamos sempre online para ajudar e tirar suas dúvidas, fale conosco por email ou WhatsApp.
                            </p>
                            <button class="redirect-button" mat-flat-button [routerLink]="'/duvidas'">fale
                                conosco</button>
                        </mat-card>
                        </div>
                    </div> -->
                    <mat-card class="main-card">
                        <div class="row">
                            <h1>
                                Vaquinhas Eleitorais:
                            </h1>
                            <div class="slider col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row between-lg between-md between-sm between-xs between">
                                    <button mat-icon-button matSuffix matTooltip="Pesquisar" (click)="previousPage()">
                                        <span matSuffix class="input-icon material-icons">keyboard_arrow_left</span>
                                    </button>
                                    <div class="candidatos" *ngIf="vaquinhaEleitoral">
                                        <div class="candidato" (click)="moreVaquinha(vaquinhaEleitoral[page])">
                                            <div class="candidato-avatar center-cropped"
                                                style="background-image: url({{getPictureVaquinha(vaquinhaEleitoral[page])}});">
                                            </div>
                                            <div class="text" style="padding-top: 0;">
                                                <h1 class="candidato-nome">{{getNameVaquinha(vaquinhaEleitoral[page])}}
                                                    <br>
                                                    {{ getVaquinhaTotal(vaquinhaEleitoral, page) | currency: "R$" }}
                                                </h1>
                                                
                                            </div>
                                        </div>
                                        <!-- <div class="candidato candidato2" (click)="more(candidatos[page + 1])">
                                            <div class="candidato-avatar center-cropped"
                                                style="background-image: url({{getPictureVaquinha(vaquinhaEleitoral[page + 1])}});">
                                            </div>
                                            <div class="text name" style="padding-top: 0;">
                                                <h1 class="candidato-nome">{{getNome(vaquinhaEleitoral[page + 1])}}
                                                    <br>
                                                    {{  getVaquinhaTotal(vaquinhaEleitoral, page +1) | currency: "R$" }}
                                                </h1>
                                               
                                            </div>
                                        </div> -->
                                    </div>
                                    <button mat-icon-button matSuffix matTooltip="Pesquisar" (click)="nextPage()">
                                        <span matSuffix class="input-icon material-icons">keyboard_arrow_right</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Essas são algumas das vaquinhas Quero Apoiar que já arrecadam doações
                                para suas campanhas através da nossa plataforma!
                            </p>
                            <button mat-flat-button class="confirm-button margin-top" (click)="goBusca()"><i
                                    class="fa fa-play-circle"></i>Descubra mais</button>
                        </div>
                    </mat-card>
                    <!-- <div #taxas class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <mat-card class="second-card">
                                <img class="icon" src="../../assets/image/maquininha.png" />
                                <h1 class="tax-title">
                                    Melhores taxas
                                </h1>
                                <p class="sub-sub-giga">
                                    <strong>Sem taxa de adesão e sem mensalidade</strong><br>
                                </p>
                                <p class="giga"> 3,65%</p>
                                <p class="sub-giga">
                                    <strong>Crédito e débito</strong><br>
                                     3,65% + R$ 0,60 taxa antifraude
                                </p>
                                <p class="sub-giga">
                                    <strong>Boleto</strong><br>
                                     3,65% + R$ 2,00 por transação
                                </p>

                                <button class="redirect-button" mat-flat-button (click)="goDuvidas()">Dúvidas?</button> -->
                                <!-- <p>
                                    Estamos comprometidos em ter as <strong>melhores taxas.</strong>
                                    <br />Entendemos que as campanhas têm de ser mais econômicas e contar com o apoio e
                                    financiamento
                                </p> -->
                            <!-- </mat-card> -->
                        <!-- </div> -->
                        <!-- <div #maquininha class="col-md-6 col-sm-12 col-xs-12">
                            <mat-card>
                                <img mat-card-image style="margin-bottom: 0;"
                                    src="../../assets/image/maquininha_boleto.png">
                                <div class="text" style="padding-top: 0;">
                                    <h1>Online & Maquininha & Boleto</h1>
                                    <p>Usamos todas as formas de pagamento permitidas :)</p>
                                    <button class="redirect-button" mat-flat-button (click)="goDuvidas()">Saiba
                                        mais</button>
                                </div>
                            </mat-card>
                        </div> -->
                    <!-- </div> -->
                </div>
            </div>
            </div>
            <br><br>
            <!-- h3 *ngIf="candidatos && candidatos.length > 0">Veja quem já participa</h3>
			<div class="row">
				<div class="col-xs-12 col-sm-6 col-md-3" *ngFor="let candidato of candidatos">
					<mat-card class="card-candidato">
						< <img mat-card-image class="candidato-avatar"
							[src]="getPicture(candidato)"> >
						<div class="candidato-avatar center-cropped" style="background-image: url({{getPicture(candidato)}});">

						</div>
						<div class="text" style="padding-top: 0;">
							<h1 class="candidato-nome">{{getNome(candidato)}}</h1>
							<button class="redirect-button" mat-flat-button (click)="more(candidato)">Ver mais</button>
						</div>
					</mat-card>
				</div>
			</div-->
        </div>
    </div>
</div>
