import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ComponentsModule } from 'src/components/form-components.module';
import { RouterModule } from '@angular/router';
import { PaymentsComponent } from './payments.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule, } from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


@NgModule({
    imports: [
        ComponentsModule,
        RouterModule,
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatStepperModule,
        MatCheckboxModule,
        // NgxQRCodeModule
    ],
    declarations: [PaymentsComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class PaymentsModule {}
