<!-- <mat-toolbar class="footer">
    <div class="container" *ngIf="!notHome">
        <div class="parceiros-container">
            <p>Nossos Parceiros:</p>
            <div class="lista-parceiros">
                <div *ngFor="let img of parceiros">
                    <img src[img]>
                </div>
            </div>
        </div>
        <div class="rodape">
            <p>@Crowdfunding</p>
            <p class="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nunc arcu, dapibus et scelerisque vel, fringilla at enim. In luctus.</p>
        </div>
    </div>
    <div class="container notHome" *ngIf="notHome">
        <p>Crowdfunding</p>
        <p>*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris luctus.</p>
    </div>
</mat-toolbar> -->
<footer class="rodape ">
    <div class="row middle-xs">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 centerV center-align">
            <p class="responsive-footer">QueroApoiar.com.br | CNPJ 39.586.155/0001-97</p>
            
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 centerV center-align">
            <img src="../../assets/image/seguro.png" class="img-responsive">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 center-align">
            <img class="img-responsive" src="../../assets/image/homologado-label.png">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 center-align">
            <div class="social-media center-align">
                <a target="_blank" href="https://www.facebook.com/queroapoiar.com.br/"><img class="icon" src="../../assets/image/facebook-brands.svg"></a>
                <!-- <a target="_blank" href="https://twitter.com/"><img class="icon" src="../../assets/image/twitter-brands.svg"></a> -->
                <a target="_blank" href="https://www.instagram.com/queroapoiar/"><img class="icon" src="../../assets/image/instagram-brands.svg"></a>
                <a target="_blank" href="https://www.youtube.com/channel/UCu5J0YdhMPxSoMfwZB0RK2g/videos"><img class="icon" src="../../assets/image/youtube-brands.svg"></a>
            </div>
        </div>
    </div>

</footer>