<div class="row center-lg center-md top-lg top-md">
    <div class="donations-component col-xs-12 col-sm-12 col-md-11 col-lg-11">
        <div class="row">
            <mat-card>
                <div class=" donations-container row">
                    <div class="subheader">
                        <!-- <img class="icon" src="../../assets/porquinho.png" /> -->
                        <p class="line-height text-b2">
                            Histórico de Comprovantes TSE
                        </p>
                    </div>
                    <div class="values col-lg-12">
                        <div class="row full">
                            <default-form [forms]="profileForm" [step]="0" class="col-xs-12 col-sm-6 col-md-8 col-lg-9">
                            </default-form>
                            <button (click)="onSubmit()" mat-flat-button
                                class="default-btn green full col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                Buscar</button>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                                <!-- a href="{{ ENV.API_URL }}/api/pagamentos/exportPayments?user={{ user._id }}"
                                    target="_blank">
                                    <button (click)="onSubmit()" mat-flat-button
                                        class="default-btn outline col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        Exportar Comprovante Atual</button>
                                </a -->

                            </div>
                        </div>
                    </div>
                    <div class=" donations-table-options col-md-12 col-sm-12 col-sm-12">
                        <table mat-table [dataSource]="dataSource" class="donations-table">
                            <ng-container matColumnDef="paymentCode">
                                <th mat-header-cell *matHeaderCellDef> Título </th>
                                <td mat-cell *matCellDef="let element">
                                     {{ element.fileName }}
                                     <div class="new" *ngIf="element.downloaded == false">Novo</div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="donatorInfo">
                                <th mat-header-cell *matHeaderCellDef> Versão </th>
                                <td mat-cell *matCellDef="let element">
                                    <strong
                                        class="name">{{ element.version }}</strong><br>

                                </td>
                            </ng-container>
                            <ng-container matColumnDef="donationValue">
                                <th mat-header-cell *matHeaderCellDef> Valor Comprovado </th>
                                <td mat-cell *matCellDef="let element">
                                    <strong>{{ getValue(element) | currency : "R$"}}</strong><br>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> Nº de Transferências </th>
                                <td mat-cell *matCellDef="let element">
                                  <strong>{{ element.transfers.length }}</strong>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="updatedAt">
                                <th mat-header-cell *matHeaderCellDef> Data Exportação </th>
                                <td mat-cell *matCellDef="let element">
                                    <strong>{{element.createdAt | date :"dd/MM/yyyy"}}</strong><br>
                                    <span>{{element.createdAt | date :"HH:mm"}}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="recibo">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                  <button type="button" (click)="showDonate(element)">
                                      Fazer Download
                                  </button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator [pageSize]="10"></mat-paginator>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
