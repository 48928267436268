import { PaymentService } from 'src/providers/payments.service';
import { PagseguroService } from './../../providers/pagseguro.service';
import { MatStepper } from '@angular/material/stepper';
import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { DomainProvider } from 'src/providers/domains';
import { MaquininhaService } from 'src/providers/maquininha.service';
import { SubscriptionFeeService } from 'src/providers/subscriptionFee.service';
import { CouponService } from 'src/providers/coupon.service';
import { ENV } from 'src/environments/environment-variables.token';
import { UtilsProvider } from 'src/commons/utils';
import { Router } from '@angular/router';
declare const PagSeguro;

@Component({
  selector: 'modal-paid-form',
  templateUrl: './modal-paid-form.component.html',
  styleUrls: ['../app.component.scss', './modal-paid-form.component.scss'],
})
export class ModalPaidFormComponent implements OnInit {
  public title: string;
  public content: string;
  public event: any;
  public formAddress: any;
  public formCredito: any;
  public formDebito: any;
  public formBoleto: any;
  public formPersonal: any;
  public state: any;
  public terms = false;
  public validCPF = false;
  public isValidPersonalInfos = false;

  public sessionId: any;
  public hash: any;

  public imageUrl = '../../assets/no-avatar.png';
  public userDataSocialMedia: any;
  public donateValue: any;
  userMedias = {};

  public donationValue: string;
  public userNotFound = false;
  public coupon: any = {};
  public flags = [];
  public candidateName: any;
  public candidato: any;
  public valor = '0,00';
  public barcode: any;
  public BankSlipUrl: any;
  public creditComplete = false;
  public debitComplete = false;
  public isDisabled = false;
  public completed = false;
  public messageDebit: any;
  public urlDebit: any;
  public paymentError: any;
  public pixCode: any;
  public pixSVG: any;
  public copied: boolean = false;
  public lat = -15.7801;
  public long = -47.9292;
  public coverImage: any;
  public darkStyle: any;
  public candidateUrna: any;
  public urlBankSlipGenerated = false;
  public actualAmount;
  public fee;
  public creditResponse: string;

  constructor(
    public formBuilder: FormBuilder,
    private domain: DomainProvider,
    private router: Router,
    private maquininha: MaquininhaService,
    private subscriptionFeeService: SubscriptionFeeService,
    private couponService: CouponService,
    public utils: UtilsProvider,
    public matDialogRef: MatDialogRef<ModalPaidFormComponent>,
    public pagseguroService: PagseguroService,
    private paymentService: PaymentService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(ENV) public ENV: any
  ) {}

  ngOnInit() {
    const self = this;
    this.createFormAddress();
    this.createFormPersonal();
    this.createFormCredito();
    this.createFormDebito();
    this.getFee();

    const _user = JSON.parse(localStorage.getItem('user'));
    if (_user) {
      this.formAddress[0].builder.patchValue({
        ZipCode: _user.user.cep,
        Street: _user.user.endereco,
        StateInitials: _user.user.estado,
        CityName: _user.user.municipio,
      });
    }
  }
  async getFee() {
    const fee = await this.subscriptionFeeService.getOne();
    this.fee = fee;
    this.actualAmount = fee.value;
    console.log('this.actualAmount,this.actualAmount', this.actualAmount);
  }

  createFormAddress() {
    const self = this;

    const ZipCode = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const Street = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const Number = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const District = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const StateInitials = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const CityName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const CountryName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const Complement = new FormControl('', undefined);

    this.formAddress = [
      {
        fields: [
          {
            name: 'ZipCode',
            type: 'maskCep',
            label: 'CEP',
            maxlength: 9,
            mask: { mask: '00000-000', len: 9 },
            cols: 'col-lg-3 col-md-3 cols-sm-12 col-xs-12',
            blurCallback() {
              // console.log("teste cep", self.institutionForm[0].builder.controls.cep.value);
              let cepValue = self.formAddress[0].builder.controls.ZipCode.value;
              if (cepValue.length !== 0) {
                cepValue = cepValue.replace(/\W+/g, '');
                self.buscarCep(cepValue);
              }
            },
          },
          {
            name: 'Street',
            type: 'text',
            label: 'Rua / Logradouro',
            cols: 'col-lg-9 col-md-9 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'Number',
            type: 'text',
            label: 'Número',
            cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
            blurCallback() {},
            onChange() {},
          },
          {
            name: 'Complement',
            type: 'text',
            label: 'Complemento',
            cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
            blurCallback() {},
          },
          {
            name: 'District',
            type: 'text',
            label: 'Bairro',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'CityName',
            type: 'text',
            label: 'Cidade',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'StateInitials',
            type: 'text',
            label: 'UF',
            cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
            blurCallback() {},
          },
          {
            name: 'CountryName',
            type: 'text',
            label: 'País',
            cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          ZipCode,
          Street,
          Number,
          Complement,
          District,
          CityName,
          StateInitials,
          CountryName,
        }),
        validation_messages: {},
      },
    ];
  }

  createFormPersonal() {
    const self = this;
    const name = new FormControl('', Validators.compose([Validators.required]));
    const email = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const telefone = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15),
      ])
    );
    const CPF = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        Validators.pattern('[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}\\-[0-9]{2}'),
      ])
    );
    const terms = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const cupomCode = new FormControl('', undefined);
    this.formPersonal = [
      {
        fields: [
          {
            name: 'CPF',
            label: 'CPF',
            mask: { mask: '000.000.000-00', type: 'num' },
            maxlength: 14,
            type: 'mask',
            placeholder: 'CPF:XXX.XXX.XXX-XX',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {
              if (CPF.valid) {
                const val = CPF.value.replace(/[\.\-]*/gi, '');
                if (!self.utils.cpfVerify(val)) {
                  self.utils.toast({
                    message: 'CPF inválido, verifique os dados inseridos',
                  });
                  self.validCPF = false;
                } else {
                  self.validCPF = true;
                }
              }
            },
          },
          {
            name: 'name',
            label: 'Nome Completo',
            // placeholder: 'Nome',
            type: 'text',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback: () => {},
          },
          {
            name: 'email',
            label: 'E-mail',
            // placeholder: 'E-mail',
            type: 'text',
            disabled: true,
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback: () => {},
          },
          {
            name: 'telefone',
            label: 'Celular ou Telefone',
            // placeholder: 'Telefone',
            type: 'mask',
            mask: { phone: true },
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback: () => {
              // let telValue = self.formPersonal[0].builder.controls.telefone.value;
              // if (telValue.length !== 0) {
              // 	telValue = telValue.replace(/\W+/g, '');
              // }
            },
          },
          {
            name: 'cupomCode',
            type: 'customText',
            placeholder: 'Código',
            label: 'Cupom',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            icon: 'fa-times',
            blurCallback() {},
            onChange: async (value) => {
              this.coupon = value;
              console.log('xx coupon receive', value);

              if (value?.discountAmount) {
                this.actualAmount -= value.discountAmount;
              } else {
                console.log('fee value', this.fee.value);
                this.actualAmount = this.fee.value;
              }
            },
          },
          {
            name: 'terms',
            type: 'checkbox-qa',
            label: 'Termos',
            options: {
              fields: {
                value: 'value',
                text: 'text',
              },
              items: [
                {
                  value: true,
                  text: 'Estou de acordo com os termos de uso do site.',
                },
              ],
            },
            clickCallback: (ev) => {
              // self.terms = !self.terms;
            },
          },
        ],
        builder: this.formBuilder.group({
          CPF,
          name,
          email,
          telefone,
          terms,
          cupomCode,
        }),
        validation_messages: {
          CPF: [
            { type: 'required', message: 'Preencha o campo CPF.' },
            { type: 'minlength', message: 'O CPF deve conter 11 digitos.' },
            { type: 'maxlength', message: 'O CPF deve conter 11 digitos.' },
            { type: 'pattern', message: 'O CPF deve conter somente números.' },
          ],
          name: [{ type: 'required', message: 'Preencha corretamente.' }],
          email: [{ type: 'required', message: 'Preencha corretamente.' }],
          telefone: [{ type: 'required', message: 'Preencha corretamente.' }],
          terms: [
            {
              type: 'required',
              message: 'Você deve aceitar os termos para continuar.',
            },
          ],
          cupomCode: [null],
        },
      },
    ];
    const loggedUser = localStorage.getItem('user');
    if (loggedUser) {
      console.log('loggedUser', loggedUser);
      this.formPersonal[0].builder.patchValue(JSON.parse(loggedUser).user);
    }
  }

  createFormCredito() {
    const self = this;

    const CardNumber = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const ExpirationDate = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
      ])
    );
    const SecurityCode = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ])
    );
    const CardName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    this.formCredito = [
      {
        fields: [
          // {
          // 	name: 'CPF',
          // 	label: 'CPF do titular',
          // 	mask: {mask: '000.000.000-00', type: 'num'},
          // 	maxlength: 14,
          // 	type: 'mask',
          // 	placeholder: 'CPF:XXX.XXX.XXX-XX',
          // 	blurCallback() {
          // 		if (CPF.valid) {
          // 			const val = CPF.value.replace(/[\.\-]*/gi, '');
          // 			if (!self.utils.cpfVerify(val)) {
          // 				self.utils.toast({message: 'CPF inválido, verifique os dados inseridos'});
          // 			}
          // 		}
          // 	}
          // },
          {
            name: 'CardNumber',
            type: 'mask',
            label: 'Número do cartão',
            maxlength: 9,
            mask: { mask: '0000 0000 0000 0000', len: 19, type: 'num' },
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'CardName',
            type: 'text',
            label: 'Nome do Titular',
            placeholder: 'Nome impresso no cartão',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'ExpirationDate',
            type: 'mask',
            label: 'Data de expiração',
            placeholder: 'XX/XXXX',
            mask: { mask: '00/0000', len: 7, type: 'num' },
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
          {
            name: 'SecurityCode',
            type: 'text',
            label: 'Código de segurança',
            // mask: { mask: '000?0', len: 4 , type: 'num'},
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          CardName,
          CardNumber,
          ExpirationDate,
          SecurityCode,
        }),
        validation_messages: {
          CardName: [{ type: 'required', message: 'Preencha corretamente.' }],
          CardNumber: [{ type: 'required', message: 'Preencha corretamente.' }],
          ExpirationDate: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
            {
              type: 'maxlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
          ],
          SecurityCode: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'O Código de segurança deve conter no mínimo 3 digitos.',
            },
            {
              type: 'maxlength',
              message: 'O Código de segurança deve conter no máximo 4 digitos.',
            },
          ],
        },
      },
    ];
  }

  createFormDebito() {
    const self = this;

    const CardNumber = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const ExpirationDate = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
      ])
    );
    const SecurityCode = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ])
    );
    const CardName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    this.formDebito = [
      {
        fields: [
          {
            name: 'CardNumber',
            type: 'mask',
            label: 'Número do cartão',
            mask: { mask: '0000 0000 0000 0000', len: 19, type: 'num' },
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'CardName',
            type: 'text',
            label: 'Nome do Titular',
            placeholder: 'Nome impresso no cartão',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'ExpirationDate',
            type: 'mask',
            label: 'Data de expiração',
            placeholder: 'XX/XXXX',
            mask: { mask: '00/0000', len: 7, type: 'num' },
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
          {
            name: 'SecurityCode',
            type: 'text',
            label: 'Código de segurança',

            // mask: { mask: '000?0', len: 4 , type: 'num'},
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          CardName,
          CardNumber,
          ExpirationDate,
          SecurityCode,
        }),
        validation_messages: {
          // CPF: [
          // 	{type: 'required', message: 'Preencha o campo CPF.'},
          // 	{type: 'minlength', message: 'O CPF deve conter 11 digitos.'},
          // 	{type: 'maxlength', message: 'O CPF deve conter 11 digitos.'},
          // 	{type: 'pattern', message: 'O CPF deve conter somente números.'}
          // ],
          CardName: [{ type: 'required', message: 'Preencha corretamente.' }],
          CardNumber: [{ type: 'required', message: 'Preencha corretamente.' }],
          ExpirationDate: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
            {
              type: 'maxlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
          ],
          SecurityCode: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'O Código de segurança deve conter no mínimo 3 digitos.',
            },
            {
              type: 'maxlength',
              message: 'O Código de segurança deve conter no máximo 4 digitos.',
            },
          ],
        },
      },
    ];
  }

  close() {
    this.matDialogRef.close();
  }

  onSubmit() {
    if (!this.isValidAddress()) {
      return this.utils.toast({
        message: 'Por favor, preencha o formulário corretamente.',
        action: 'OK',
        duration: 10000,
      });
    } else {
      if (!this.terms) {
        return this.utils.toast({
          message: 'Por favor, aceite nossos termos de uso.',
          action: 'OK',
          duration: 10000,
        });
      }
      const data = this.formAddress[0].builder.value;
      const _user = JSON.parse(localStorage.getItem('user'));
      this.maquininha
        .createRequest({
          userRef: _user.user._id,
          userName: _user.user.name,
          userEmail: _user.user.email,
          ...data,
        })
        .then((success) => {
          this.utils.toast({
            message: 'Solicitação enviada com sucesso!',
            action: 'SUCESSO',
            duration: 10000,
          });
          return this.matDialogRef.close();
        })
        .catch((err) => {
          this.utils.toast({
            message:
              'Você já tem uma solicitação pendente, por favor aguarde nosso retorno ou tente novamente mais tarde.',
            action: 'OK',
            duration: 10000,
          });
        });
    }
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
  }

  buscarCep(cepValue) {
    if (cepValue.length === 8) {
      this.ENV.loading = true;
      this.domain.getCEP(cepValue).then((data: any) => {
        console.log(data);

        this.formAddress[0].builder.patchValue({
          Street: data.logradouro || '',
          District: data.bairro || '',
          CityName: data.localidade || '',
          StateInitials: data.uf || '',
          CountryName: 'BR',
        });
        this.ENV.loading = false;
      });
    }
  }

  isValidAddress() {
    if (this.formAddress[0]) {
      return this.formAddress[0].builder.valid;
    }
    return false;
  }

  selectCity(city) {
    this.state = city.state[0].initials;
    if (this.state) {
      this.formAddress[0].builder.patchValue({
        estado: this.state,
      });
    }
  }
  goForward(stepper: MatStepper) {
    stepper.next();
  }
  isValidPersonal(stepper: MatStepper) {
    const { invalid, controls } = this.formPersonal[0].builder;
    if (invalid) {
      if (controls.CPF.invalid)
        return this.utils.toast({
          message: 'Digite o campo do CPF corretamente',
        });
      if (!this.validCPF) return this.utils.toast({ message: 'CPF inválido' });
      if (controls.name.invalid)
        return this.utils.toast({
          message: 'Preencha o campo "Nome Completo"',
        });
      if (controls.telefone.invalid)
        return this.utils.toast({
          message: 'Preencha o campo "Telefone" corretamente',
        });
    } else {
      stepper.next();
    }
  }
  checkDisabled() {
    return this.isDisabled;
  }

  async submitTransaction(method) {
    const { controls: controlsPersonal, invalid: invalidPersonal } =
      this.formPersonal[0].builder;
    const { controls: controlsAddress, invalid: invalidAddress } =
      this.formAddress[0].builder;

    if (invalidPersonal || invalidAddress) return;

    let value = this.fee.value ?? 0;

    if (this.coupon?.discountAmount) {
      value -= this.coupon.discountAmount;
    }

    const query = {
      userInfo: {
        Name: controlsPersonal.name.value,
        Identity: controlsPersonal.CPF.value,
        Phone: controlsPersonal.telefone.value,
        Email: controlsPersonal.email.value,
      },
      addressInfo: {
        ZipCode: controlsAddress.ZipCode.value,
        Street: controlsAddress.Street.value,
        Number: controlsAddress.Number.value,
        Complement: '',
        District: controlsAddress.District.value,
        CityName: controlsAddress.CityName.value,
        StateInitials: controlsAddress.StateInitials.value,
        CountryName: controlsAddress.CountryName.value,
      },
      value: value,
      coupon: this.coupon,
      method: method,
      cardInfo: {},
    };

    if (method == 'credito') {
      if (
        !this.formCredito[0] ||
        !this.formCredito[0].builder.valid ||
        !this.formCredito[0].builder.controls.CardName.value ||
        !this.formCredito[0].builder.controls.CardNumber.value ||
        !this.formCredito[0].builder.controls.ExpirationDate.value ||
        !this.formCredito[0].builder.controls.SecurityCode.value
        // !this.formCredito[0].builder.controls.CPF.value
      ) {
        return this.utils.toast({
          message: 'Preencha corretamente o formulário',
        });
      }
      this.isDisabled = true;

      const card = { ...this.formCredito[0].builder.value };
      card.CardNumber = card.CardNumber.replace(/\s/g, '');

      query.cardInfo = {
        Holder: this.formCredito[0].builder.controls.CardName.value,
        CardNumber: card.CardNumber,
        ExpirationDate:
          this.formCredito[0].builder.controls.ExpirationDate.value,
        SecurityCode: this.formCredito[0].builder.controls.SecurityCode.value,
      };
    }

    this.paymentService
      .SubscriptionTransaction(query)
      .then((res) => {
        if (method == 'boleto') {
          this.barcode = res.info.Barcode;
          this.BankSlipUrl = res.info.BankSlipUrl;
        } else if (method == 'pix') {
          this.pixSVG = res.info.QrCode;
          this.pixCode = res.info.Key;
        } else if (method == 'credito') {
          if (res.info.Status == 3) this.creditResponse = res.info.Description;
          console.log('testtt agaub', res.info.Status == 3);
          console.log('testtt agaub', res.info.Description);
        }
        this.isDisabled = false;
        this.completed = true;
      })
      .catch((err) => {
        this.isDisabled = false;
        console.log('err', err);
        this.utils.toast({
          message:
            'Ocorreu um erro ao gerar o pagamento. Por favor, verifique seus dados ou tente novamente mais tarde',
        });
      });
  }

  async submitCredit() {
    if (
      !this.formCredito[0] ||
      !this.formCredito[0].builder.valid ||
      !this.formCredito[0].builder.controls.CardName.value ||
      !this.formCredito[0].builder.controls.CardNumber.value ||
      !this.formCredito[0].builder.controls.ExpirationDate.value ||
      !this.formCredito[0].builder.controls.SecurityCode.value
      // !this.formCredito[0].builder.controls.CPF.value
    ) {
      return this.utils.toast({
        message: 'Preencha corretamente o formulário',
      });
    }
    this.isDisabled = true;
    const card = { ...this.formCredito[0].builder.value };
    card.CardNumber = card.CardNumber.replace(/\s/g, '');
    // const [errPrepare, prepare] = await this.utils.tryCatch(this.prepareTransaction());
    // console.log('errPrepare', errPrepare);
    // console.log('prepare', prepare);
    // if (errPrepare) { return this.utils.toast({ message: 'Pagamento não realizado - Preparação dos dados falhou' }); }
    // const [errBrand, brand] = await this.utils.tryCatch(this.getCardBrand(card));
    // if (errBrand) { return this.utils.toast({ message: 'Pagamento não realizado' }); }
    // card.CardBrand = brand;
    // const [errToken, token] = await this.utils.tryCatch(this.encryptCard(card));
    // console.log('token', token);
    // console.log('errToken', errToken);
    // const [errToken, token] = await this.utils.tryCatch(this.getCardToken(card));
    // if (errToken) { return this.utils.toast({ message: 'Pagamento não realizado' }); }
    // const [errToken, token] = await this.utils.tryCatch(this.encryptCard(card));
    // console.log('token', token);
    // console.log('errToken', errToken);
    // if (errToken) { return this.utils.toast({ message: 'Erro ao realizar pagamento, verifique os dados do cartão' }); }
    // return;

    this.paymentService
      .payment({
        cpf: this.formPersonal[0].builder.controls.CPF.value,
        name: this.formPersonal[0].builder.controls.name.value,
        email: this.formPersonal[0].builder.controls.email.value,
        cupomCode: this.formPersonal[0].builder.controls.cupomCode.value,
        address: {
          street: this.formAddress[0].builder.controls.Street.value,
          number: this.formAddress[0].builder.controls.Number.value,
          zipcode: this.formAddress[0].builder.controls.ZipCode.value,
          neighborhood: this.formAddress[0].builder.controls.District.value,
          city: this.formAddress[0].builder.controls.CityName.value,
          state: this.formAddress[0].builder.controls.StateInitials.value,
          country: this.formAddress[0].builder.controls.CountryName.value,
        },
        payment_method_code: 'credit_card',
        product_code: this.data.product_code,
        holder_name: this.formCredito[0].builder.controls.CardName.value,
        registry_code: this.formPersonal[0].builder.controls.CPF.value,
        card_expiration:
          this.formCredito[0].builder.controls.ExpirationDate.value,
        allow_as_fallback: false,
        card_number: card.CardNumber,
        card_cvv: this.formCredito[0].builder.controls.SecurityCode.value,
        amount: this.data.amount,
        // ...this.formAddress[0].builder.value,
        // ...this.formPersonal[0].builder.value,
        // ...this.formCredito[0].builder.value,
        // valor: this.valor,
        // cardToken: token,
        // // cardHash: this.hash,
        // candidato: this.candidato,
      })
      .then((res) => {
        this.isDisabled = false;
        this.completed = true;
        console.log(res);
        if (res.charge.status !== 'paid') {
          this.utils.toast({ message: 'Erro ao realizar pagamento' });
          this.paymentError = res.last_transaction.gateway_message;
        } else {
          this.utils.toast({ message: 'Pagamento realizado!' });
        }
      })
      .catch((e: any) => {
        this.isDisabled = false;
        console.log('err', e);
        this.utils.toast({
          message:
            'Ocorreu um erro ao gerar o pagamento. Por favor, verifique seus dados ou tente novamente mais tarde',
        });
      });
  }
  async submitPIX() {
    // if (!this.formBoleto || !this.formBoleto[0].builder.controls.CPF.value) {
    // 	return this.utils.toast({message: 'Preencha corretamente o formulário'});
    // }
    console.log('PIX');
    this.isDisabled = true;
    this.paymentService
      .payment({
        cpf: this.formPersonal[0].builder.controls.CPF.value,
        name: this.formPersonal[0].builder.controls.name.value,
        email: this.formPersonal[0].builder.controls.email.value,
        cupomCode: this.formPersonal[0].builder.controls.cupomCode.value,
        address: {
          street: this.formAddress[0].builder.controls.Street.value,
          number: this.formAddress[0].builder.controls.Number.value,
          zipcode: this.formAddress[0].builder.controls.ZipCode.value,
          neighborhood: this.formAddress[0].builder.controls.District.value,
          city: this.formAddress[0].builder.controls.CityName.value,
          state: this.formAddress[0].builder.controls.StateInitials.value,
          country: this.formAddress[0].builder.controls.CountryName.value,
        },
        payment_method_code: 'pix',
        product_code: this.data.product_code,
        amount: this.data.amount,
      })
      .then((res) => {
        if (!res.brcode) {
          this.utils.toast({ message: 'Erro ao gerar PIX' });
          this.paymentError = res.message;
        } else {
          this.utils.toast({ message: 'PIX gerado com sucesso!' });
        }
        this.isDisabled = false;
        console.log(res);
        this.pixCode = res.brcode;
        this.pixSVG = res.pdf.replace('pdf', 'qrcode');
        this.completed = true;

        if (
          res.last_transaction &&
          res.last_transaction.gateway_response_code == '400'
        ) {
          this.utils.toast({ message: 'Erro ao gerar PIX' });
          this.paymentError = res.last_transaction.gateway_message;
        } else {
          this.utils.toast({ message: 'PIX gerado com sucesso!' });
        }
        this.isDisabled = false;
        this.pixCode =
          res.last_transaction.gateway_response_fields.qrcode_original_path;
        this.pixSVG = res.last_transaction.gateway_response_fields.qrcode_path;
        this.actualAmount = res.amount;
        console.log(this.pixCode);
        this.completed = true;
      })
      .catch((e: any) => {
        this.isDisabled = false;
        console.log('err', e);
        this.utils.toast({
          message:
            'Ocorreu um erro ao gerar o pagamento! Por favor, verifique seus dados ou tente novamente mais tarde',
        });
      });
  }
  async submitBankSlip() {
    // if (!this.formBoleto || !this.formBoleto[0].builder.controls.CPF.value) {
    // 	return this.utils.toast({message: 'Preencha corretamente o formulário'});
    // }
    console.log('boleto');
    this.isDisabled = true;
    this.paymentService
      .payment({
        cpf: this.formPersonal[0].builder.controls.CPF.value,
        name: this.formPersonal[0].builder.controls.name.value,
        email: this.formPersonal[0].builder.controls.email.value,
        cupomCode: this.formPersonal[0].builder.controls.cupomCode.value,
        address: {
          street: this.formAddress[0].builder.controls.Street.value,
          number: this.formAddress[0].builder.controls.Number.value,
          zipcode: this.formAddress[0].builder.controls.ZipCode.value,
          neighborhood: this.formAddress[0].builder.controls.District.value,
          city: this.formAddress[0].builder.controls.CityName.value,
          state: this.formAddress[0].builder.controls.StateInitials.value,
          country: this.formAddress[0].builder.controls.CountryName.value,
        },
        payment_method_code: 'bank_slip',
        product_code: this.data.product_code,
        amount: this.data.amount,
      })
      .then((res) => {
        if (!res.print_url) {
          this.utils.toast({ message: 'Erro ao gerar boleto' });
          this.paymentError = res.info.payment_response.message;
        } else {
          this.utils.toast({ message: 'Boleto gerado com sucesso!' });
        }
        this.isDisabled = false;
        console.log(res);
        this.barcode =
          res.last_transaction.gateway_response_fields.typable_barcode;
        this.BankSlipUrl = res.print_url;
        this.actualAmount = res.amount;
        this.completed = true;
      })
      .catch((e: any) => {
        this.isDisabled = false;
        console.log('err', e);
        this.utils.toast({
          message:
            'Ocorreu um erro ao gerar o pagamento! Por favor, verifique seus dados ou tente novamente mais tarde',
        });
      });
  }
  encryptCard(card) {
    return new Promise((resolve, reject) => {
      const cardInfo = PagSeguro.encryptCard({
        publicKey:
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB',
        holder: card.CardName,
        number: card.CardNumber,
        expMonth: card.ExpirationDate.split('/')[0],
        expYear: card.ExpirationDate.split('/')[1],
        securityCode: card.SecurityCode,
      });

      if (cardInfo.hasErrors) {
        return reject(cardInfo.errors);
      }
      resolve(cardInfo.encryptedCard);
    });
  }

  cancel() {
    this.matDialogRef.close();
    if (this.completed) {
      window.location.reload();
    }
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => {
      if (this.copied) this.copied = false;
    }, 3000);
    console.log(this.copied);
  }
}
