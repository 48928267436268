import { Component, OnInit, Inject } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ENV } from 'src/environments/environment-variables.token';
import { HttpService } from 'src/providers/http.service';
import { ScrollService } from 'src/providers/scroll.service';
import { Subscription } from 'rxjs';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { CandidateService } from '../../providers/candidate.service';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/providers/auth.service';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UtilsProvider } from 'src/commons/utils';
import { VaquinhaService } from 'src/providers/vaquinha.service';
import { PoliticalService } from 'src/providers/political.service';

@Component({
  selector: 'app-search-candidates',
  templateUrl: './search-candidates.component.html',
  styleUrls: ['./search-candidates.component.scss'],
})
export class SearchCandidatesComponent implements OnInit {
  title = 'Quero Apoiar - Home';
  userForm = this.formBuilder.group({
    email: ['', [Validators.required]],
  });
  public elements = ['cadastro', 'comofunciona', 'contato'];
  public candidatos: any;

  constructor(
    private router: Router,
    private vaquinhaService: VaquinhaService,
    private formBuilder: FormBuilder,
    public utils: UtilsProvider,
    private politicalService: PoliticalService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENV) public ENV: any
  ) {}

  public limit = 8;
  public finalSearch: boolean = false;
  public keyword: any;
  private skip: any = 0;
  public listYear = [];
  public selectedYear: any;

  ngOnInit() {
    window.scrollTo(0, 0);
    const self = this;

    self.politicalService.getListElectionPeriod().then((res: any) => {
      const filter = res.itens.filter(
        (item) => item.year == new Date().getFullYear()
      );
      if(filter.length === 0){
        this.selectedYear = res.itens[0].year;
      } else {
        console.log("res", res)
        console.log("yaer", filter)
        this.selectedYear = filter[0].year;
      }
      this.listYear = res.itens;
    });
    this.makeRequest({ newRequest: true });
  }

  async expand() {
    this.skip++;
    this.makeRequest({ newRequest: false });
  }

  search() {
    this.skip = 0;
    this.finalSearch = false;
    this.makeRequest({ newRequest: true });
  }

  scrollTo(el: any) {
    if (this[el]) {
      this[el].nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  navigate(candidato: any) {
    this.router.navigate([candidato.url], candidato);
  }

  makeRequest({ newRequest }) {
    return new Promise(async (resolve, reject) => {
      const [err, request] = await this.utils.tryCatch(
        this.vaquinhaService.getElectionVaquinha({
          skip: this.skip,
          limit: this.limit,
          params: this.keyword,
          year: this.selectedYear,
        })
      );
      console.log("selectedYear", this.selectedYear)

      if (err) {
        console.log('err make request', err);
        reject(err);
      }

      if (request.length == 0 || request.length != this.limit) {
        this.finalSearch = true;
      }

      if (newRequest) {
        this.candidatos = request;
      } else {
        this.candidatos.push(...request);
      }

      resolve(request);
    });
  }
}