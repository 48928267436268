import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CandidateService } from '../../providers/candidate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UtilsProvider } from '../../commons/utils';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { AccountService } from 'src/providers/account.service.';

@Component({
	selector: 'app-oldDonations',
	templateUrl: './oldDonations.component.html',
	styleUrls: ['./oldDonations.component.scss']
})
export class OldDonationsComponent implements OnInit {
	name: string;
	cpf: number;
	date: string;
	donationMethod: string;
	value: string;
	candidato: any;

	public donationsList: any;
	public year: number = 2018;
	public skip: any = 0;
	public limit = 20;

	public tableLabels = {
		name: 'Nome',
		cpf: 'CPF',
		date: 'Data',
		paymentCode: "Código de pagamento",
		donationMethod: 'Forma de Doação',
		value: 'Valor',
	};

	constructor(
		private candidateService: CandidateService,
		private activatedRoute: ActivatedRoute,
		public dialog: MatDialog,
		private accountProvider: AccountService,
		private router: Router,
		private utils: UtilsProvider,
	) {

	}

	ngOnInit() {
		window.scrollTo(0, 0);
		this.activatedRoute.queryParams.subscribe(async params => {
			console.log('_params', params);
			this.candidato = JSON.parse(params.candidato);
			// this.candidateService.getOldDonations({ userRef: this.candidato.userRef, year: 2020}).then((res) => {
			// 	this.donationsList = res;
			// }).catch(e => {
			// 	this.utils.toast({ message: 'Não foi possível buscar informações do candidato' });
			// });
			this.makeRequest({ newRequest: true });
		});
	}

	makeRequest({ newRequest }) {
		return new Promise(async (resolve, reject) => {
			const [err, request] = await this.utils.tryCatch(
				this.candidateService.getOldDonations(
					{
						userRef: this.candidato.userRef, 
						year: this.year,
						_skip: this.skip,
						_limit: this.limit,
					
					}
				)
			);
	
			if (err) {
				console.log('err make request', err);
				reject(err);
			}
	
		//   if (request.length == 0 || request.length != this.limit) {
		// 	this.finalSearch = true;
		//   }
	
			if (newRequest) {
				this.donationsList = request;
			} else {
				this.donationsList.push(...request);
			}
	
			resolve(request);
		});
	}

	// public showDonate(donate: any) {
	// 	console.log(donate);

	// 	let formaPagamento;
	// 	if (donate.type === 'credito') { formaPagamento = 'Crédito'; }
	// 	else if (donate.type === 'debito') { formaPagamento = 'Débito'; }
	// 	else if (donate.type === 'boleto') { formaPagamento = 'Boleto'; }

	// 	this.accountProvider.getAccount({ user: donate.userRef._id }).then(account => {
	// 		const candidate = donate.userRef;
	// 		const params = {
	// 			candidateEmail: candidate.email,
	// 			userEmail: donate.donatorInfo.Email,
	// 			candidateCpf: candidate.cpf,
	// 			candidateCnpj: account ? account.cnpj : undefined,
	// 			userCpf: donate.donatorInfo.Identity,
	// 			partido: candidate.partido,
	// 			cargo: candidate.cargo,
	// 			candidateName: candidate.name,
	// 			userName: donate.donatorInfo.nomecompleto ? donate.donatorInfo.nomecompleto : donate.donatorInfo.Name,
	// 			value: 'R$' + String(donate.donationValue).replace('.', ','),
	// 			date: donate.updatedAt,
	// 			pagamento: formaPagamento,
	// 			//error: donate.paymentInfo.ResponseDetail.Description
	// 		};

	// 		const date = new Date(params.date).toLocaleDateString() + ' - ' +
	// 			new Date(params.date).toLocaleTimeString();
	// 		let template;
	// 		if (params.candidateName) { template = `<p><b>Candidato(a):</b> ${params.candidateName}</p>`; }
	// 		if (params.candidateCpf) { template += `<p><b>CPF do Candidato:</b> ${params.candidateCpf}</p>`; }
	// 		if (params.candidateCnpj) { template += `<p><b>CNPJ do Candidato:</b> ${params.candidateCnpj}</p>`; }
	// 		if (params.partido) { template += `<p><b>Partido:</b> ${params.partido}</p>`; }
	// 		if (params.cargo) { template += `<p><b>Cargo:</b> ${params.cargo}</p>`; }
	// 		if (params.userName) { template += `<p><b>Nome do Doador:</b> ${params.userName}</p>`; }
	// 		if (params.userCpf) { template += `<p><b>CPF do Doador:</b> ${params.userCpf}</p>`; }
	// 		if (params.date) { template += `<p><b>Data:</b> ${date}</p>`; }
	// 		if (params.value) { template += `<p><b>Valor:</b> ${params.value}</p>`; }
	// 		if (params.pagamento) { template += `<p><b>Forma de Pagamento:</b> ${params.pagamento}</p>`; }

	// 		const dialogRef = this.dialog.open(ModalDefaultComponent, {
	// 			autoFocus: false,
	// 			data: {
	// 				title: 'Dados do Recibo',
	// 				message: template
	// 			}
	// 		});

	// 		dialogRef.afterClosed().subscribe(result => {

	// 		});
	// 	})
	// }

	getName(donator: any) {
		return String(donator.donatorInfo.Name).toLowerCase();
	}

	search(year) {
		this.year = year;
		this.skip = 0;
		console.log("this.yaer", this.year)
		this.candidateService.getOldDonations({ userRef: this.candidato.userRef, year: year,_skip: this.skip,
			_limit: this.limit}).then((res) => {
			this.donationsList = res;
		}).catch(e => {
			this.utils.toast({ message: 'Não foi possível buscar informações do candidato' });
		});
	}

	async expand() {
		this.skip++;
		this.makeRequest({ newRequest: false });
	  }
}


