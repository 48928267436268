<mat-toolbar class="menu" [class.hide]="headerHide">
    <!-- <div class="header-title">
        <a [routerLink]="['/']"><img src="../../assets/logo/logo_full.png"/></a>
    </div>
    <div class="menu-container">
        <button [routerLink]="'/'" fragment="produtos">Produtos</button>
        <button (click)="onClick('produtos')">Produtos</button>
        <button [routerLink]="['/maquininha']" *ngIf="!notHome">Maquininha</button> 
        <button  [routerLink]="'/'" fragment="depoimentos">Depoimentos</button>
        <button  (click)="onClick('depoimentos')">Depoimentos</button>
        <button  [routerLink]="'/maquininha'" fragment="duvidas" *ngIf="notHome">Dúvidas?</button>
        <button mat-button [matMenuTriggerFor]="contact">Contatos</button>
        <button class="menu-button" *ngIf="!this.ENV.USER" [routerLink]="['/login']">Acessar</button>
        <button class="menu-button" *ngIf="this.ENV.USER" (click)="logout()">Sair</button>
        <mat-menu #contact="matMenu" class="teste">
            <button mat-menu-item>
                <span class="material-icons">phone_in_talk</span> (11) 91111-1111
            </button>
            <button mat-menu-item [routerLink]="['/contato']">
                <span class="material-icons">email</span> contato@teste.com
            </button>
        </mat-menu>
    </div> -->
    <div class="purple"></div>
    <nav class="navBar">
        <div class=""></div>
        <div class="alignLogo">
            <div class="row middle-xs between-sm between-xs">
                <div class="col-xs-9 col-sm-9 col-md-4 col-lg-4">
                    <div class="row middle-xs middle-sm">
                        <div class="toggle col-xs-2 col-sm-2 col-md-0 col-lg-0">
                            <i class="icon fas fa-bars" (click)="changeOpen()"></i>
                        </div>
                        <div class="col-xs-10 col-sm-10 col-md-7 col-lg-7">
                            <a [routerLink]="'/'"><img class="img-responsive logo"
                                    src="../../assets/logo/logo_white.svg"></a>
                        </div>
                    </div>
                </div>
                <div *ngIf="!paid" class="col-xs-3 col-sm-3 col-md-4 col-lg-4">
                    <button (click)="openDialogMAquininha()" mat-flat-button class="confirm-button">
                        Pague agora a taxa de inscrição
                    </button>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-4 col-lg-4">
                    <div class="menu-interno">
                        <ul>
                            <!-- li><a [routerLink]="'/'">Início</a></li -->
                            <li><i class="icon disable fas fa-bell"></i></li>
                            <li>
                                <div class="avatar-img center-cropped" [matMenuTriggerFor]="profile"
                                    style="background-image: url({{imageUrl}});"></div>
                            </li>
                            <mat-menu #profile="matMenu" class="teste">
                                <button mat-menu-item [routerLink]="'/'">
                                    <!-- span class="material-icons">phone_in_talk</span --> Voltar para o Site
                                </button>
                                <!-- button mat-menu-item [routerLink]="'/perfil'">
                                    <span class="material-icons">phone_in_talk</span> Editar Perfil
                                </button>
                                <button *ngIf="isCandidate()" mat-menu-item (click)="doacoes()">
                                    <span class="material-icons">phone_in_talk</span> Minhas Doações
                                </button -->
                               <!--  <button *ngIf="isCandidate()" mat-menu-item (click)="visualizar()">
                                    span class="material-icons">phone_in_talk</span> Ver Minha Página
                                </button>  -->
                                <button mat-menu-item (click)="logout()">
                                    <!-- span class="material-icons">email</span--> Sair
                                </button>
                            </mat-menu>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>

</mat-toolbar>