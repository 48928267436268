<div class="row center-lg center-md top-lg top-md">
  <div class="d-component col-xs-12 col-sm-12 col-md-11 col-lg-11">
    <mat-card [ngStyle]="{ 'min-height': screenSizeHeight + 'px' }">
      <div class="dcontainer row">
        <div class="values col-md-12 col-lg-12">
          <div class="row full center-middle">
            <!-- <img class="icon" src="../../assets/porquinho.png" /> -->
            <p class="line-height text-b2 col-md-8">Minhas Vaquinhas</p>
            <div class="col-md-4 ">
              <!-- <button (click)="openDialog({},false,true, false)" mat-flat-button
                                class="default-btn green full col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                Criar Vaquinha</button> -->
              <button
                (click)="openDialog({}, false, true, true)"
                mat-flat-button
                class="default-btn purple full col-xs-12 col-sm-12 col-md-12 col-lg-12"
              >
                Criar Vaquinha (Eleitoral)
              </button>
            </div>
          </div>
        </div>
        <div class="donations-table-options col-md-12 col-sm-12 col-sm-12">
          <table mat-table [dataSource]="dataSource" class="donations-table">
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Descrição</th>
              <td mat-cell *matCellDef="let element">
                <div class="display">{{ element.title }} <br /></div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <div class="display">
                  <!-- {{verifyStatus(element.subscribeRef)}} &nbsp; -->
                  {{ element.active ? "Ativo" : "Inativo" }} &nbsp;
                  <!-- <div *ngIf="verifyStatus(element.subscribeRef) == 'Inativo'" class="display"> -->
                  <div *ngIf="element.active == false" class="display">
                    <button
                      class="statusz"
                      (mouseover)="showWarningStatus(element._id, true)"
                      (mouseout)="showWarningStatus(element._id, false)"
                    >
                      <div class="status">?</div>
                    </button>
                  </div>
                  <div
                    class="messageStatus"
                    *ngIf="warningStatus == element._id"
                  >
                    Pague a taxa de inscrição para <strong>ativar</strong> a
                    vaquinha
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Tipo</th>
              <td mat-cell *matCellDef="let element">
                {{ element.typeRef.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="initAt">
              <th mat-header-cell *matHeaderCellDef>Início</th>
              <td mat-cell *matCellDef="let element">
                <strong>{{ element.initAt | date : "dd/MM/yyyy" }}</strong
                ><br />
                <p style="text-align: center">
                  {{ element.initAt | date : "HH:mm" }}
                </p>
              </td>
            </ng-container>
            <ng-container matColumnDef="endAt">
              <th mat-header-cell *matHeaderCellDef>Término</th>
              <td mat-cell *matCellDef="let element">
                <strong>{{ element.endAt | date : "dd/MM/yyyy" }}</strong
                ><br />
                <p style="text-align: center">
                  {{ element.endAt | date : "HH:mm" }}
                </p>
              </td>
            </ng-container>

            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button
                  (click)="redirect(element)"
                  mat-flat-button
                  class="default-btn green full col-xs-12 col-sm-12 col-md-12 col-lg-12"
                >
                  <i class="fa fa-money"></i> SAQUE
                </button>
                <!-- <button (click)="openDialog(element, false, false)" mat-flat-button
                                class="default-btn green full col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <i class="fa fa-regular fa-eye"></i>&nbsp;DETALHES
                              </button> -->
                <button
                  (click)="showVaquinha(element)"
                  mat-flat-button
                  class="default-btn green full col-xs-12 col-sm-12 col-md-12 col-lg-12"
                >
                  <i class="fa fa-regular fa-eye"></i> VER VAQUINHA
                </button>
                <button
                  (click)="
                    openDialog(
                      element,
                      true,
                      false,
                      element.typeRef.name == 'Eleitoral' ? true : false
                    )
                  "
                  mat-flat-button
                  class="default-btn green full col-xs-12 col-sm-12 col-md-12 col-lg-12"
                >
                  <i class="fa fa-regular fa-edit"></i> &nbsp; EDITAR
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="image">
              <th mat-header-cell *matHeaderCellDef>Imagem</th>
              <td class="img-size" mat-cell *matCellDef="let element">
                <img src="{{ element.image.link }}" alt="vaquinha" />
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator [pageSize]="10"></mat-paginator>
        </div>
      </div>
    </mat-card>
  </div>
</div>
