import {
  Component,
  Output,
  Inject,
  Input,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropModalComponent } from 'src/app/image-crop-modal/image-crop-modal.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PoliticalService } from 'src/providers/political.service';

@Component({
  selector: 'app-candidate-vaquinha',
  templateUrl: './candidate-vaquinha.component.html',
  styleUrls: ['./candidate-vaquinha.component.scss'],
})
export class CandidateVaquinhaComponent implements OnInit {
  constructor(
    public utils: UtilsProvider,
    public politicalProvider: PoliticalService,
    public formBuilder: FormBuilder,
    @Inject(ENV) public ENV: any,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.vaquinhaForm();
  }

  @Input() data: any = {};
  @Output() Event = new EventEmitter<{}>();
  public formCandidate: any;
  public croppedImage: any = '../../assets/no-avatar.png';
  private fileToUpload: any = null;

  public Editor = ClassicEditor;
  public model = '';
  public selectedPoliticalParty: String;
  public selectedPosition: String;

  setDefaultValue() {
    if (this.data && this.data.edit) {
      this.croppedImage = this.data.image.host + this.data.image.full;
      this.fileToUpload = this.data.image.host + this.data.image.full;
      this.formCandidate[0].builder.controls.tags.value = this.data.tags;
      this.formCandidate[0].builder.controls.where.value = this.data.where;
      this.formCandidate[0].builder.controls.name.value = this.data.name;
      // this.model = this.data.bio;
    }
  }

  async vaquinhaForm() {
    if (this.data && this.data.edit) {
      this.selectedPoliticalParty = this.data.politicalParty._id;
      this.selectedPosition = this.data.position._id;
    }

    const fields = () =>
      new FormControl('', Validators.compose([Validators.required]));

    const politicalParty = fields();
    const position = fields();
    const tags = fields();
    const where = fields();
    const name = fields();

    const [err, positions] = await this.utils.tryCatch(
      this.politicalProvider.getPositions()
    );

    const [err1, politicalParties] = await this.utils.tryCatch(
      this.politicalProvider.getPoliticalParties()
    );

    this.formCandidate = [
      {
        fields: [
          {
            name: 'politicalParty',
            selected: this.selectedPoliticalParty,
            type: 'select',
            cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
            label: 'Seu Partido',
            options: {
              fields: {
                value: '_id',
                text: 'name',
              },
              items: politicalParties.itens,
            },
            clickCallback: (data) => {},
          },
          {
            name: 'position',
            selected: this.selectedPosition,
            type: 'select',
            cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
            label: 'Cargo Pretendido',
            options: {
              fields: {
                value: '_id',
                text: 'name',
              },
              items: positions.itens,
            },
            clickCallback: (data) => {},
          },
          {
            name: 'where',
            type: 'text',
            label: 'Concorrendo por (local)',
            placeholder: 'Ex. São Paulo',
            cols: 'col-lg-4 col-md-4 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          // {
          //   name: 'name',
          //   type: 'text',
          //   label: 'Nome do candidato',
          //   cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
          //   blurCallback() {},
          // },
          {
            name: 'tags',
            type: 'text',
            label: 'Bandeiras defendidas',
            placeholder: 'Ex. Saúde, Economia, Meio Ambiente, Cidadania',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          politicalParty,
          position,
          tags,
          where,
          // name,
        }),
        validation_messages: {
          tags: [],
          // name: [
          //   {
          //     type: 'required',
          //     message: 'Preencha o campo "Nome do candidato".',
          //   },
          // ],
          where: [
            {
              type: 'required',
              message: 'Preencha o campo "Concorrendo por".',
            },
          ],
          politicalParty: [
            { type: 'required', message: 'Selecione o campo "Partido".' },
          ],
          position: [
            { type: 'required', message: 'Selecione o campo "Cargo".' },
          ],
        },
      },
    ];
    this.setDefaultValue();
  }

  fileChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropModalComponent, {
      autoFocus: false,
      data: { event: event, aspect: 1 / 1 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.croppedImage = result.image;
        (this.fileToUpload = result.image), 'profilepic';
        console.log('xxxd FILEEEEE: ', this.fileToUpload);
      }
    });
  }

  back() {
    this.Event.emit({
      back: true,
    });
  }

  next() {
    const form = this.formCandidate[0].builder;
    const verify = [
      { field: 'position', message: 'Selecione o campo cargo.' },
      { field: 'politicalParty', message: 'Selecione o campo partido.' },
      { field: 'where', message: 'Preencha o campo "Concorrendo por".' },
      // { field: 'name', message: 'Preencha o campo "Nome do candidato".' },
    ];

    const ok: Array<any> = verify.map((item) => {
      form.controls[`${item.field}`]['touched'] = true;
      if (form.controls[`${item.field}`]['invalid']) {
        this.utils.toast({
          message: item.message,
          action: '❌',
        });
        return true;
      }
    });

    if (
      !this.fileToUpload ||
      this.croppedImage == '../../assets/no-avatar.png'
    ) {
      this.utils.toast({
        message: 'Selecione a foto do candidato',
        action: '❌',
      });
      return true;
    }

    if (!ok.includes(true)) {
      this.Event.emit({
        ...this.formCandidate[0].builder.value,
        image: this.fileToUpload,
        // bio: this.model,
      });
    }
  }
}
