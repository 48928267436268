<div class="card">
  <div class="close">
      <!-- <img class="logo" src="../../assets/logo/logo.svg" /> -->
    <button mat-flat-button class="btn1"  [mat-dialog-close]="'false'">
      <i class="fa fa-close"></i>
    </button>
  </div>


  <div *ngIf="data.edit === false && data.create === false; else elseBlock" >
    <app-view-vaquinha [data]="data"></app-view-vaquinha>
  </div>

  <ng-template #elseBlock>
    <div class="stepper">
      <div *ngFor="let step of stepper; let i = index"
      class="stepper-item
      {{currentStep == step.index ? 'active': ''}}
      {{currentStep <= step.index ? '' : 'completed'}}
      ">
        <div class="step-counter">{{i+1}}</div>
        <div class="step-name">{{step.title}}</div>
      </div>
    </div>

      <!-- <div class="container"> -->

          <app-type-vaquinha [data]="body.type" (Event)="getElectoralType($event)" *ngIf="currentStep === 1"></app-type-vaquinha>



          <app-img-vaquinha [data]="data.create == true ? body.image : data.image.link" [edit]="data.create == true ? 'false' : 'true'" (Event)="getImageVaquinha($event)" *ngIf="currentStep === 2"></app-img-vaquinha>
          <!-- <div class="align-center">
            <div>
                <img class="img" [src]="croppedImage" >
            </div>
            <br>
            <input [hidden]="true" type="file" #fileInput accept="image/*" onclick="this.value = null"
                (change)="fileChangeEvent($event)">
            <button mat-flat-button class="btn2" (click)="fileInput.click()">{{croppedImage.startsWith('h') ? 'Alterar' : 'Selecionar'}}  Foto da vaquinha</button>
            <br>
            <span>Tamanho Máximo do Arquivo: 3MB</span><br><br>
          </div> -->



          <app-form-vaquinha [data]="data.create ? body : data" (Event)="getFormVaquinha($event)" *ngIf="currentStep === 3"></app-form-vaquinha>

          <app-account-vaquinha [data]="this.data.create ? body.account : data.account" [last]="lastStepAccount" (Event)="getAccount($event)" *ngIf="currentStep === 4"></app-account-vaquinha>

          <app-candidate-vaquinha [data]="this.data.create ? body.candidate : data.candidate" (Event)="getCandidateProfile($event)" *ngIf="currentStep === 5"></app-candidate-vaquinha>


    <!-- </div> -->
    <!-- <div class="btn-container" *ngIf="(lastStepAccount == false && currentStep == 6) || (lastStepAccount == true && currentStep == 5)">
    </div> -->

  </ng-template>
</div>
