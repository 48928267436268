import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { AuthService } from 'src/providers/auth.service';
import { UtilsProvider } from 'src/commons/utils';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/providers/user.service';
import { ENV } from 'src/environments/environment-variables.token';
import { HttpService } from 'src/providers/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FaleConoscoModalComponent } from '../fale-conosco-modal/fale-conosco-modal.component';
import { MatButton } from '@angular/material/button';
import { DomainProvider } from 'src/providers/domains';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public registerForm: any;
  public registerFormStep: any;
  public CepValido: boolean = true;
  public userTypes: any;
  private permissionsList: any;
  public formAddress: any;
  public formPersonal: any;
  public state: any;
  public terms = false;
  public candidate = false;
  public errorAlias = false;
  public url;
  public isDisabled = false;
  private errorCpf = false;
  public resolvedCaptcha = false;
  public siteKey = '';

  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    public auth: AuthService,
    public utils: UtilsProvider,
    public dialog: MatDialog,
    public http: HttpClient,
    public httpService: HttpService,
    private domain: DomainProvider,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    @Inject(ENV) public ENV: any
  ) {
    this.siteKey = this.ENV.siteKey;
  }

  async ngOnInit() {
    console.log('this.ENV', this.siteKey);
    window.scrollTo(0, 0);
    const self = this;
    const [errGetPermissions, res] = await this.utils.tryCatch(
      this.userService.getRoles()
    );
    if (errGetPermissions) {
      return this.utils.toast({
        message:
          'Não foi possível buscar por dados do formulário, tente novamente mais tarde.',
        action: 'Ok',
        duration: 10000,
      });
    }
    this.permissionsList = res.data;
    const permissions = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    //const role = new FormControl('', Validators.compose([Validators.required]));
    const email = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    );
    const password = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const username = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const cpf = new FormControl('', Validators.compose([Validators.required]));
    const confirmPassword = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        RxwebValidators.compare({ fieldName: 'password' }),
      ])
    );

    this.registerFormStep = 0;
    this.registerForm = [
      {
        fields: [
          {
            name: 'username',
            type: 'text',
            placeholder: 'Nome Completo',
            blurCallback() {},
          },
          {
            name: 'cpf',
            type: 'mask',
            placeholder: 'CPF (Apenas Números)',
            mask: { mask: '000.000.000-00', type: 'num', len: 14 },
            minlength: 14,
            maxlength: 14,
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {
              if (cpf.valid) {
                //cpf.value.replace(/[\.\-]*/gi, "")
                let val = cpf.value.replace(/[\.\-]*/gi, '');
                self.registerForm[0].builder.controls.cpf.value = val;
                if (!self.utils.cpfVerify(val)) {
                  self.utils.toast({
                    message: 'CPF inválido, verifique os dados inseridos.',
                    action: 'Ok',
                    duration: 10000,
                  });
                  self.errorCpf = true;
                } else {
                  self.errorCpf = false;
                }
              }
            },
          },
          {
            name: 'email',
            type: 'email',
            placeholder: 'E-mail',
            blurCallback() {},
          },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Senha',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'confirmPassword',
            type: 'password',
            placeholder: 'Confirme sua senha',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          email,
          password,
          confirmPassword,
          username,
          cpf,
        }),
        validation_messages: {
          username: [{ type: 'required', message: 'Preencha o campo nome.' }],
          cpf: [{ type: 'required', message: 'Preencha o campo cpf.' }],
          email: [{ type: 'required', message: 'Preencha o campo e-mail.' }],
          password: [{ type: 'required', message: 'Preencha o campo senha.' }],
          confirmPassword: [
            {
              type: 'required',
              message: 'Preencha o campo confirme sua senha.',
            },
            { type: 'compare', message: 'Os campos devem ter o mesmo valor.' },
          ],
          // permissions: [
          // 	{ type: 'required', message: 'Escolha o tipo de usuário que você gostaria de se cadastrar' }
          // ],
        },
      },
    ];
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
    console.log(this.isDisabled);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  clean() {
    this.registerForm[0].builder.reset();
  }

  openDialog() {
    const dialogRef = this.dialog.open(FaleConoscoModalComponent, {
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  // buscarCep(cepValue) {
  // 	if (cepValue.length === 8) {
  // 		this.ENV.loading = true;

  // 		 this.domain.getCEP(cepValue).then((data: any) => {
  // 			console.log("aoba" ,data);
  // 			this.candidateForm[0].builder.patchValue({
  // 				endereco: data.logradouro || '',
  // 				 estado: data.uf || '',
  // 			});
  // 			this.ENV.loading = false;
  // 			if(data.erro){
  // 				this.CepValido = false
  // 				this.utils.toast({ message: 'CEP inválido, verifique os dados inseridos.' });
  // 				return false
  // 			}
  // 			this.CepValido = true

  // 			 return true
  // 		})
  // 	}
  // }

  checkDisabled() {
    return this.isDisabled;
  }

  onSubmit(event) {
    console.log('CPF VALIDO', this.errorCpf);

    if (!this.resolvedCaptcha) {
      this.utils.toast({
        message: 'Captcha incorreto',
        action: 'Ok',
        duration: 8000,
      });
      return;
    }

    if (
      this.registerForm &&
      !this.registerForm[0].builder.controls.username.valid
    ) {
      this.utils.toast({
        message: 'Por favor, preencha o campo Nome corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.registerForm &&
      !this.registerForm[0].builder.controls.password.valid
    ) {
      this.utils.toast({
        message: 'Por favor, preencha o campo Senha corretamente',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.registerForm &&
      !this.registerForm[0].builder.controls.confirmPassword.valid
    ) {
      this.utils.toast({
        message: 'As senhas não coincidem, por favor revise sua senha',
        action: 'Ok',
        duration: 8000,
      });
    } else if (
      this.registerForm &&
      (!this.registerForm[0].builder.controls.cpf.valid ||
        this.errorCpf == true)
    ) {
      this.utils.toast({
        message: 'CPF inválido, por favor digite um CPF válido',
        action: 'Ok',
        duration: 8000,
      });
    } else if (!this.registerForm[0].builder.valid) {
      this.utils.toast({
        message: 'Por favor, preencha corretamente o formulário',
        action: 'Ok',
        duration: 8000,
      });
    } else if (this.terms == false) {
      return this.utils.toast({
        message: 'Por favor, leia e concorde com nossos termos de uso.',
        action: 'Ok',
        duration: 10000,
      });
    } else {
      const roleCandidato = this.permissionsList.find(
        (el) => String(el.descricao) === String('candidato')
      );
      const data =
        this.registerForm && this.registerForm[0]
          ? {
              ...this.registerForm[0].builder.value,
              termos: this.terms,
              role: roleCandidato._id,
            }
          : { ...this.registerForm[0].builder.value, termos: this.terms };
      console.log('TA ENVIANDO ISSO: ', data);
      this.isDisabled = true;
      this.auth
        .register(data)
        .then((res) => {
          this.clean();
          // if (res !== '') { this.router.navigate(['/perfil']); }
          this.openSnackBar('Você se cadastrou com sucesso!', 'OK');
          this.ENV.logged = true;
          this.isDisabled = false;
          this.router.navigate(['/admin/minhas-vaquinhas']);
        })
        .catch((err) => {
          return (this.isDisabled = false);
        });
    }
  }
  public resolved(captchaResponse: string): void {
    if (captchaResponse) {
      this.resolvedCaptcha = true;
    } else {
      this.resolvedCaptcha = false;
    }
  }
}
