import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsProvider } from 'src/commons/utils';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DomainProvider } from '../../providers/domains';
import { ENV } from 'src/environments/environment-variables.token';
import { PaymentService } from '../../providers/payments.service';
import { MatButton } from '@angular/material/button';
import { MatStepper } from '@angular/material/stepper';
import { PagseguroService } from '../../providers/pagseguro.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalTermDonationComponent } from '../modal-term-donation/modal-term-donation.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
declare const PagSeguroDirectPayment;
declare const PagSeguro;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  title: any;
  public sessionId: any;
  public hash: any;
  public CepValido: boolean = true;
  public imageUrl = '../../assets/no-avatar.png';
  public userDataSocialMedia: any;
  public donateValue: any;
  userMedias = {};
  public donationValue: string;
  public userNotFound = false;
  public flags = [];
  public candidateName: any;
  public vaquinha: any;
  public valor = '0,00';
  public formAddress: any;
  public formCredito: any;
  public formDebito: any;
  public formBoleto: any;
  public formPersonal: any;
  public barcode: any;
  public BankSlipUrl: any;
  public pixCode: any;
  public pixSVG: any;
  public creditComplete = false;
  public debitComplete = false;
  public copied = false;
  public terms = false;
  public isDisabled = false;
  public completed = false;
  public validCPF = false;
  public messageDebit: any;
  public urlDebit: any;
  public paymentError: any;
  public lat = -15.7801;
  public long = -47.9292;
  public coverImage: any;
  public darkStyle: any;
  public candidateUrna: any;
  public isValidPersonalInfos = false;
  public boleto: any;
  public creditCard: boolean = true;
  public pix: boolean = true;
  public newBoleto: boolean = true;
  public paymentType: any;
  public payment: any = [];
  @ViewChild('iframePix') iframePix: ElementRef<HTMLInputElement>;

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private utils: UtilsProvider,
    private domain: DomainProvider,
    public formBuilder: FormBuilder,
    public pagseguroService: PagseguroService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(ENV) public ENV: any
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    /* this.candidateService.getProfiles({all: true}).then(res => { */
    this.activatedRoute.queryParams.subscribe(async (params) => {
      console.log('_params', params);
      this.vaquinha = JSON.parse(params.vaquinha);
      this.paymentType = this.vaquinha.paymentType;
      console.log('paymentType', this.paymentType);
      this.candidateUrna = String(this.vaquinha.urna).toLowerCase();
      this.candidateName = String(this.vaquinha.name).toLowerCase();
      this.valor = params.valor;

      // this.popitup('https://google.com');
    });

    this.getPaymentType();

    // this.pixSVG = "https://dzpxyxks1bfmb.cloudfront.net/production/itau/N91376335_qrcode.svg";
    // this.pixCode = "00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/731a80bc-da72-44e0-a213-05571e2aff345204000053039865802BR5925YAPAY PAGAMENTOS ONLINE L6009SAO PAULO62070503***63041BF8"
    // this.matIconRegistry.addSvgIcon(
    // 	'pixQrcode',
    // 	this.domSanitizer.bypassSecurityTrustResourceUrl(this.pixSVG)
    // );
    // this.pagseguroService.createRequest().then(r => {
    // 	console.log('r', r);
    // 	this.sessionId = r.sessionId;
    // 	PagSeguroDirectPayment.setSessionId('857394459ae243b2ab711791e6ab3b30');
    // 	PagSeguroDirectPayment.onSenderHashReady((response) => {
    // 		if (response.status === 'error') {
    // 			console.log(response.message);
    // 			return false;
    // 		}
    // 		this.hash = response.senderHash; // Hash estará disponível nesta variável.
    // 		console.log('hash', this.hash);

    // 	});
    // });
    // console.log("iframe",this.iframePix)
    // const pix = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pixSVG);
    // this.iframePix.nativeElement.innerHTML = "<img src='"+pix+"' />";

    this.darkStyle = this.utils.mapStyle;
    this.createFormAddress();
    this.createFormPersonal();
    this.createFormCredito();
    this.createFormDebito();
    // this.getCoordinates()
    // this.createFormBankSlip();
    this.boleto = Number(this.valor) < 60 ? true : false;
  }

  getPixUrl(url) {
    const safeHTML = this.domSanitizer.bypassSecurityTrustResourceUrl(
      url as string
    );
    return safeHTML;
  }

  getCoordinates() {
    const state = this.vaquinha.estado;
    const city = this.vaquinha.municipio;
    this.httpClient
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${city},+${state}&key=AIzaSyCA7-Z1-aAW-fBiE8-lXk31JvXO1FOJVLI`
      )
      .subscribe((res) => {
        const refs = JSON.parse(JSON.stringify(res));
        this.lat = refs.results[0].geometry.location.lat;
        this.long = refs.results[0].geometry.location.lng;
        console.log(this.lat, this.long);
      });
  }

  getPaymentType() {
    this.paymentType.map((item) => {
      const value = {
        method: item.method,
      };

      if (value.method == 'credito') {
        return (this.creditCard = false);
      } else if (value.method == 'pix') {
        return (this.pix = false);
      } else if (value.method == 'boleto') {
        return (this.newBoleto = false);
      }

      this.payment.push(value);
    });
  }

  checkDisabled() {
    return this.isDisabled;
  }
  // const address = {
  // 	ZipCode: data.ZipCode,
  // 	Street: data.Street,
  // 	Number: data.Number,
  // 	District: data.District,
  // 	StateInitials: data.StateInitials,
  // 	CityName: data.CityName,
  // 	CountryName: data.CountryName,
  // 	Complement: data.Complement
  // }
  // const cardInfo = {
  // 	CardNumber: data.CardNumber,
  // 	ExpirationDate:data.ExpirationDate,
  // 	SecurityCode:data.SecurityCode
  // }

  createFormAddress() {
    const self = this;

    const ZipCode = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(9)])
    );
    const Street = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const Number = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const District = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const StateInitials = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const CityName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const CountryName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const Complement = new FormControl('', undefined);

    this.formAddress = [
      {
        fields: [
          {
            name: 'ZipCode',
            type: 'maskCep',
            label: 'CEP',
            maxlength: 9,
            mask: { mask: '00000-000', len: 9 },
            cols: 'col-lg-3 col-md-3 cols-sm-12 col-xs-12',
            async blurCallback() {
              // console.log("teste cep", self.institutionForm[0].builder.controls.cep.value);
              let cepValue = self.formAddress[0].builder.controls.ZipCode.value;
              if (cepValue.length !== 0) {
                cepValue = cepValue.replace(/\W+/g, '');
                await self.buscarCep(cepValue);
              }
            },
          },
          {
            name: 'Street',
            type: 'text',
            label: 'Rua / Logradouro',
            cols: 'col-lg-9 col-md-9 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'Number',
            type: 'text',
            label: 'Número',
            cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
            blurCallback() {},
          },
          {
            name: 'Complement',
            type: 'text',
            label: 'Complemento',
            cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
            blurCallback() {},
          },
          {
            name: 'District',
            type: 'text',
            label: 'Bairro',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'CityName',
            type: 'text',
            label: 'Cidade',
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'StateInitials',
            type: 'text',
            label: 'UF',
            cols: 'col-lg-3 col-md-3 col-sm-4 col-xs-4',
            blurCallback() {},
          },
          {
            name: 'CountryName',
            type: 'text',
            label: 'País',
            cols: 'col-lg-3 col-md-3 col-sm-8 col-xs-8',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          ZipCode,
          Street,
          Number,
          Complement,
          District,
          CityName,
          StateInitials,
          CountryName,
        }),
        validation_messages: {
          ZipCode: [{ type: 'required', message: 'Preencha corretamente' }],
          Street: [{ type: 'required', message: 'Preencha corretamente' }],
          Number: [{ type: 'required', message: 'Preencha corretamente' }],
          Comlpement: [{ type: 'required', message: 'Preencha corretamente' }],
          District: [{ type: 'required', message: 'Preencha corretamente' }],
          CityName: [{ type: 'required', message: 'Preencha corretamente' }],
          StateInitials: [
            { type: 'required', message: 'Preencha corretamente' },
          ],
          CountryName: [{ type: 'required', message: 'Preencha corretamente' }],
        },
      },
    ];
  }

  createFormPersonal() {
    const self = this;
    const name = new FormControl('', Validators.compose([Validators.required]));
    const email = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const telefone = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(14)])
    );
    const CPF = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        Validators.pattern('[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}\\-[0-9]{2}'),
      ])
    );
    this.formPersonal = [
      {
        fields: [
          {
            name: 'CPF',
            label: 'CPF',
            mask: { mask: '000.000.000-00', len: '14', type: 'num' },
            maxlength: 14,
            type: 'mask',
            placeholder: 'CPF:XXX.XXX.XXX-XX',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {
              if (CPF.valid) {
                const val = CPF.value.replace(/[\.\-]*/gi, '');
                if (!self.utils.cpfVerify(val)) {
                  self.utils.toast({
                    message: 'CPF inválido, verifique os dados inseridos.',
                  });
                  self.validCPF = false;
                } else {
                  self.validCPF = true;
                }
              }
            },
          },
          {
            name: 'name',
            label: 'Nome Completo',
            // placeholder: 'Nome',
            type: 'text',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback: () => {},
          },
          {
            name: 'email',
            label: 'E-mail',
            // placeholder: 'E-mail',
            type: 'text',
            disabled: true,
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback: () => {},
          },
          {
            name: 'telefone',
            label: 'Celular',
            // placeholder: 'Telefone',
            type: 'mask',
            mask: { mask: '(00)00000-0000', len: '14' },
            cols: 'col-lg-6 col-md-6 col-sm-12 col-xs-12',
            blurCallback: () => {
              // let telValue = self.formPersonal[0].builder.controls.telefone.value;
              // if (telValue.length !== 0) {
              // 	telValue = telValue.replace(/\W+/g, '');
              // }
            },
          },
          // {
          // 	name: 'terms',
          // 	type: 'checkbox-qa',
          // 	label: 'Termos',
          // 	options: {
          // 		fields: {
          // 			value: 'value',
          // 			text: 'text'
          // 		},
          // 		items: [{
          // 			value: true,
          // 			text: 'Estou ciente e autorizo, nos termos da Lei eleitoral e da Lei Geral de Proteção de Dados que meus dados fornecidos para esta doação sejam disponibilizados de acordo com as mencionadas Leis.'
          // 		}]
          // 	},
          // 	clickCallback: (ev) => {
          // 		// self.terms = !self.terms;
          // 	}
          // }
        ],
        builder: this.formBuilder.group({
          CPF,
          name,
          email,
          telefone,
          // terms
        }),
        validation_messages: {
          CPF: [
            { type: 'required', message: 'Preencha o campo CPF.' },
            { type: 'minlength', message: 'O CPF deve conter 11 digitos.' },
            { type: 'maxlength', message: 'O CPF deve conter 11 digitos.' },
            { type: 'pattern', message: 'O CPF deve conter somente números.' },
          ],
          name: [{ type: 'required', message: 'Preencha corretamente.' }],
          email: [{ type: 'required', message: 'Preencha corretamente.' }],
          telefone: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'Preencha corretamente seu número de Celular.',
            },
          ],
          // terms: [
          // 	{ type: 'required', message: 'Você deve aceitar os termos para continuar.' },
          // ]
        },
      },
    ];
    const loggedUser = localStorage.getItem('user');
    if (loggedUser) {
      console.log('loggedUser', loggedUser);
      this.formPersonal[0].builder.patchValue(JSON.parse(loggedUser).user);
    }
  }

  createFormCredito() {
    const self = this;

    const CardNumber = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const ExpirationDate = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
      ])
    );
    const SecurityCode = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ])
    );
    const CardName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    this.formCredito = [
      {
        fields: [
          // {
          // 	name: 'CPF',
          // 	label: 'CPF do titular',
          // 	mask: {mask: '000.000.000-00', type: 'num'},
          // 	maxlength: 14,
          // 	type: 'mask',
          // 	placeholder: 'CPF:XXX.XXX.XXX-XX',
          // 	blurCallback() {
          // 		if (CPF.valid) {
          // 			const val = CPF.value.replace(/[\.\-]*/gi, '');
          // 			if (!self.utils.cpfVerify(val)) {
          // 				self.utils.toast({message: 'CPF inválido, verifique os dados inseridos'});
          // 			}
          // 		}
          // 	}
          // },
          {
            name: 'CardNumber',
            type: 'mask',
            label: 'Número do cartão',
            maxlength: 9,
            mask: { mask: '0000 0000 0000 0000', len: 19, type: 'num' },
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'CardName',
            type: 'text',
            label: 'Nome do Titular',
            placeholder: 'Nome impresso no cartão',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'ExpirationDate',
            type: 'mask',
            label: 'Data de expiração',
            placeholder: 'XX/XXXX',
            mask: { mask: '00/0000', len: 7, type: 'num' },
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
          {
            name: 'SecurityCode',
            type: 'text',
            label: 'Código de segurança',
            // mask: { mask: '000?0', len: 4 , type: 'num'},
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          CardName,
          CardNumber,
          ExpirationDate,
          SecurityCode,
        }),
        validation_messages: {
          CardName: [{ type: 'required', message: 'Preencha corretamente.' }],
          CardNumber: [{ type: 'required', message: 'Preencha corretamente.' }],
          ExpirationDate: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
            {
              type: 'maxlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
          ],
          SecurityCode: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'O Código de segurança deve conter no mínimo 3 digitos.',
            },
            {
              type: 'maxlength',
              message: 'O Código de segurança deve conter no máximo 4 digitos.',
            },
          ],
        },
      },
    ];
  }

  createFormDebito() {
    const self = this;

    const CardNumber = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    const ExpirationDate = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
      ])
    );
    const SecurityCode = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ])
    );
    const CardName = new FormControl(
      '',
      Validators.compose([Validators.required])
    );
    this.formDebito = [
      {
        fields: [
          {
            name: 'CardNumber',
            type: 'mask',
            label: 'Número do cartão',
            mask: { mask: '0000 0000 0000 0000', len: 19, type: 'num' },
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'CardName',
            type: 'text',
            label: 'Nome do Titular',
            placeholder: 'Nome impresso no cartão',
            cols: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
            blurCallback() {},
          },
          {
            name: 'ExpirationDate',
            type: 'mask',
            label: 'Data de expiração',
            placeholder: 'XX/XXXX',
            mask: { mask: '00/0000', len: 7, type: 'num' },
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
          {
            name: 'SecurityCode',
            type: 'text',
            label: 'Código de segurança',

            // mask: { mask: '000?0', len: 4 , type: 'num'},
            cols: 'col-lg-6 col-md-6 col-sm-6 col-xs-6',
            blurCallback() {},
          },
        ],
        builder: this.formBuilder.group({
          CardName,
          CardNumber,
          ExpirationDate,
          SecurityCode,
        }),
        validation_messages: {
          // CPF: [
          // 	{type: 'required', message: 'Preencha o campo CPF.'},
          // 	{type: 'minlength', message: 'O CPF deve conter 11 digitos.'},
          // 	{type: 'maxlength', message: 'O CPF deve conter 11 digitos.'},
          // 	{type: 'pattern', message: 'O CPF deve conter somente números.'}
          // ],
          CardName: [{ type: 'required', message: 'Preencha corretamente.' }],
          CardNumber: [{ type: 'required', message: 'Preencha corretamente.' }],
          ExpirationDate: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
            {
              type: 'maxlength',
              message: 'A data de expiração deve estar no formato XX/XXXX',
            },
          ],
          SecurityCode: [
            { type: 'required', message: 'Preencha corretamente.' },
            {
              type: 'minlength',
              message: 'O Código de segurança deve conter no mínimo 3 digitos.',
            },
            {
              type: 'maxlength',
              message: 'O Código de segurança deve conter no máximo 4 digitos.',
            },
          ],
        },
      },
    ];
  }

  createFormBankSlip() {
    const self = this;
    const CPF = new FormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        Validators.pattern('[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}\\-[0-9]{2}'),
      ])
    );
    this.formBoleto = [
      {
        fields: [
          {
            name: 'CPF',
            label: 'CPF do titular da cobrança',
            mask: { mask: '000.000.000-00', type: 'num' },
            maxlength: 14,
            type: 'mask',
            placeholder: 'CPF:XXX.XXX.XXX-XX',
            blurCallback(CPF) {
              console.log('chamando tuper');
              if (CPF.valid) {
                const val = CPF.value.replace(/[\.\-]*/gi, '');
                if (!self.utils.cpfVerify(val)) {
                  self.utils.toast({
                    message: 'CPF inválido, verifique os dados inseridos.',
                  });
                }
              }
            },
          },
        ],
        builder: this.formBuilder.group({
          CPF,
        }),
        validation_messages: {
          CPF: [
            { type: 'required', message: 'Preencha o campo CPF.' },
            { type: 'minlength', message: 'O CPF deve conter 11 digitos.' },
            { type: 'maxlength', message: 'O CPF deve conter 11 digitos.' },
            { type: 'pattern', message: 'O CPF deve conter somente números.' },
          ],
        },
      },
    ];
  }

  buscarCep(cepValue) {
    if (cepValue.length === 8) {
      this.ENV.loading = true;

      this.domain.getCEP(cepValue).then((data: any) => {
        console.log(data);
        // ZipCode,
        // Street,
        // Number,
        // Complement,
        // District,
        // CityName,
        // StateInitials,
        // CountryName
        // bairro: "Vila Mariana"
        // cep: "04102-001"
        // complemento: "de 2702 a 3636 - lado par"
        // gia: "1004"
        // ibge: "3550308"
        // localidade: "São Paulo"
        // logradouro: "Rua Vergueiro"
        // uf: "SP"
        this.formAddress[0].builder.patchValue({
          Street: data.logradouro || '',
          District: data.bairro || '',
          CityName: data.localidade || '',
          StateInitials: data.uf || '',
          holder_name: this.formPersonal[0].builder.controls.name.value,
          CountryName: 'BR',
        });
        this.ENV.loading = false;
        if (data.erro) {
          this.CepValido = false;
          this.utils.toast({
            message: 'CEP inválido, verifique os dados inseridos.',
          });
          return false;
        }
        this.CepValido = true;

        return true;
      });
    }
  }

  isValidAddress() {
    if (this.formAddress[0]) {
      return this.formAddress[0].builder.valid;
    }
    return false;
  }
  goForward(stepper: MatStepper) {
    stepper.next();
  }
  isValidPersonal(stepper: MatStepper) {
    console.log('terms', this.terms);
    // if (!this.validCPF) { return this.utils.toast({message: 'CPF inválido'}); }
    if (!this.terms) {
      this.utils.toast({
        message: 'Você deve aceitar os termos para continuar',
      });

      return false;
    }
    if (this.validCPF) {
      if (this.formPersonal[0] && this.formPersonal[0].builder.valid) {
        this.isValidPersonalInfos = true;
        stepper.next();
      }
    } else {
      this.utils.toast({
        message: 'CPF inválido, verifique os dados inseridos.',
      });
    }

    return false;
  }

  async submitCredit() {
    if (
      !this.formCredito[0] ||
      !this.formCredito[0].builder.valid ||
      !this.formCredito[0].builder.controls.CardName.value ||
      !this.formCredito[0].builder.controls.CardNumber.value ||
      !this.formCredito[0].builder.controls.ExpirationDate.value ||
      !this.formCredito[0].builder.controls.SecurityCode.value
      // !this.formCredito[0].builder.controls.CPF.value
    ) {
      return this.utils.toast({
        message: 'Preencha corretamente o formulário',
      });
    }
    this.isDisabled = true;
    const card = { ...this.formCredito[0].builder.value };
    card.CardNumber = card.CardNumber.replace(/\s/g, '');
    console.log('card', card);
    const paymentTypes = this.paymentType.filter(
      (item) => item.method == 'credito'
    );

    this.paymentService
      .creditoSafe2pay({
        cpf: this.formPersonal[0].builder.controls.CPF.value
          .replaceAll('-', '')
          .replaceAll('.', ''),
        ...this.formAddress[0].builder.value,
        ...this.formPersonal[0].builder.value,
        valor: this.valor,
        ...card,
        // cardToken: token,
        // cardHash: this.hash,
        vaquinha: this.vaquinha,
        paymentTypes: paymentTypes[0]._id,
      })
      .then((res) => {
        this.isDisabled = false;
        this.completed = true;
        console.log('res', res);
        // if (res.info.bill.payment_response && res.info.bill.payment_response.code !== '20000') {
        // 	this.utils.toast({ message: 'Erro ao realizar pagamento' });
        // 	this.paymentError = res.info.bill.payment_response.message;
        // } else {
        // 	this.utils.toast({ message: 'Pagamento realizado!' });
        // }
        this.formCredito[0].builder.reset();
        this.utils.toast({
          message: 'Pagamento realizado! Seu pagamento esta sendo processado.',
        });
      })
      .catch((e: any) => {
        this.isDisabled = false;
        console.log('err', e);
        this.utils.toast({
          message: e
            ? e
            : 'Ocorreu um erro ao gerar o pagamento. Por favor, verifique seus dados ou tente novamente mais tarde',
        });
      });
  }
  // async submitCredit() {
  // 	if (
  // 		!this.formCredito[0]
  // 		|| !this.formCredito[0].builder.valid
  // 		|| !this.formCredito[0].builder.controls.CardName.value
  // 		|| !this.formCredito[0].builder.controls.CardNumber.value
  // 		|| !this.formCredito[0].builder.controls.ExpirationDate.value
  // 		|| !this.formCredito[0].builder.controls.SecurityCode.value
  // 		// !this.formCredito[0].builder.controls.CPF.value
  // 	) {
  // 		return this.utils.toast({ message: 'Preencha corretamente o formulário' });
  // 	}
  // 	this.isDisabled = true;
  // 	const card = {...this.formCredito[0].builder.value};
  // 	card.CardNumber = card.CardNumber.replace(/\s/g, '');

  // 	// const [errPrepare, prepare] = await this.utils.tryCatch(this.prepareTransaction());
  // 	// console.log('errPrepare', errPrepare);
  // 	// console.log('prepare', prepare);
  // 	// if (errPrepare) { return this.utils.toast({ message: 'Pagamento não realizado - Preparação dos dados falhou' }); }
  // 	// const [errBrand, brand] = await this.utils.tryCatch(this.getCardBrand(card));
  // 	// if (errBrand) { return this.utils.toast({ message: 'Pagamento não realizado' }); }
  // 	// card.CardBrand = brand;

  // 	// const [errToken, token] = await this.utils.tryCatch(this.getCardToken(card));
  // 	// if (errToken) { return this.utils.toast({ message: 'Pagamento não realizado' }); }
  // 	// const [errToken, token] = await this.utils.tryCatch(this.encryptCard(card));
  // 	// console.log('token', token);
  // 	// console.log('errToken', errToken);
  // 	// if (errToken) { return this.utils.toast({ message: 'Pagamento não realizado' }); }
  // 	// return;

  // 	this.paymentService.credito({
  // 		...this.formAddress[0].builder.value,
  // 		...this.formPersonal[0].builder.value,
  // 		...this.formCredito[0].builder.value,
  // 		valor: this.valor,
  // 		// cardToken: token,
  // 		// cardHash: this.hash,
  // 		vaquinha: this.vaquinha,
  // 	}).then(res => {
  // 		this.isDisabled = false;
  // 		this.completed = true;
  // 		console.log(res);
  // 		if (res.info.HasError) {
  // 			this.utils.toast({ message: 'Pagamento não realizado' });
  // 			this.paymentError = res.info.ResponseDetail.Description;
  // 		} else {
  // 			this.utils.toast({ message: res.message });
  // 		}
  // 	})
  // 		.catch((e: any) => {
  // 			this.isDisabled = false;
  // 			console.log('err', e);
  // 			this.utils.toast({ message: e });
  // 		});
  // }

  /*submitDebit() {
		if (
			!this.formDebito[0]
			|| !this.formDebito[0].builder.valid
			|| !this.formDebito[0].builder.controls.CardNumber.value
			|| !this.formDebito[0].builder.controls.CardName.value
			|| !this.formDebito[0].builder.controls.ExpirationDate.value
			|| !this.formDebito[0].builder.controls.SecurityCode.value
			// !this.formDebito[0].builder.controls.CPF.value
		) {
			return this.utils.toast({ message: 'Preencha corretamente o formulário' });
		}

		this.isDisabled = true;
		this.paymentService.debito({
			...this.formAddress[0].builder.value,
			...this.formPersonal[0].builder.value,
			...this.formDebito[0].builder.value,
			valor: this.valor,
			vaquinha: this.vaquinha
		}).then(res => {
			this.isDisabled = false;
			this.debitComplete = true;
			this.messageDebit = res.info.ResponseDetail.Message;
			this.urlDebit = res.info.ResponseDetail.AuthenticationUrl;
			// this.popitup(this.urlDebit);
			// console.log(res)
			if (res.info.HasError || res.info.ResponseDetail.Status === 8) {
				this.utils.toast({ message: 'Pagamento não realizado' });
				this.paymentError = res.info.ResponseDetail.Description;
			} else {
				this.utils.toast({ message: res.message });
				this.popitup(this.urlDebit);
			}
		})
			.catch((e: any) => {
				this.isDisabled = false;
				console.log('err', e);
				this.utils.toast({ message: e });
			});

	}*/

  fixParameterData() {
    return new Promise((resolve, reject) => {
      const paymentTypes = this.paymentType.filter(
        (item) => item.method == 'boleto'
      );

      const { name, CPF, telefone, email } = this.formPersonal[0].builder.value;

      const params = {
        userInfo: {
          Name: name,
          Identity: CPF.replace(/\D/g, ''),
          Phone: telefone,
          Email: email,
        },
        addressInfo: {
          ...this.formAddress[0].builder.value,
        },
        valor: this.valor,
        vaquinha: {
          title: this.vaquinha.name,
          id: this.vaquinha.id,
        },
        paymentTypeRef: paymentTypes[0]._id,
      };

      resolve(params);
    });
  }

  async submitBankSlip() {
    // if (!this.formBoleto || !this.formBoleto[0].builder.controls.CPF.value) {
    // 	return this.utils.toast({message: 'Preencha corretamente o formulário'});
    // }
    console.log('boleto');
    this.isDisabled = true;

    const params = await this.fixParameterData();

    console.log('data boleto,', params);
    this.paymentService
      .boletoSafe2Pay(params)
      .then((res) => {
        // if (!res.info.bill.charges[0].print_url) { // vindi
        if (res.hasOwnProperty('err')) {
          this.utils.toast({ message: 'Erro ao gerar boleto' });
          this.paymentError =
            'Não foi possível gerar o boleto, tente novamente mais tarde.';
          // res.info.payment_response.message; //vindi
        } else {
          this.utils.toast({ message: 'Boleto gerado com sucesso!' });
        }
        this.isDisabled = false;
        console.log(res);
        this.barcode = res.info.Barcode;
        // res.info.bill.charges[0].last_transaction.gateway_response_fields.typable_barcode; //vindi
        this.BankSlipUrl = res.info.BankSlipUrl;
        // res.info.bill.charges[0].print_url; vindi
        this.completed = true;
      })
      .catch((e: any) => {
        this.isDisabled = false;
        console.log('err', e);
        this.utils.toast({
          message: e
            ? e
            : 'Ocorreu um erro ao gerar o pagamento! Por favor, verifique seus dados ou tente novamente mais tarde',
        });
      });
  }

  async submitPIX() {
    console.log('PIX');
    this.isDisabled = true;

    const params = await this.fixParameterData();

    this.paymentService
      .pixSafe2Pay(params)
      .then((res) => {
        if (!res.info) {
          this.utils.toast({ message: 'Erro ao gerar PIX' });
          this.paymentError = res.message;
        } else {
          this.utils.toast({ message: 'PIX gerado com sucesso!' });
        }
        this.isDisabled = false;
        console.log(res);
        this.pixCode = res.info.Key;
        this.pixSVG = res.info.QrCode;
        // this.pixSVG = https://images.safe2pay.com.br/pix/862a04b835e2432d9150c295a457cabe.png'; // teste
        // res.info.bill.charges[0].last_transaction.gateway_response_fields.qrcode_original_path; // vindi

        /* this.pixSVG = this.domSanitizer.bypassSecurityTrustResourceUrl(
          res.info.bill.charges[0].last_transaction.gateway_response_fields
            .qrcode_path as string
        ); //vindi */
        this.completed = true;
      })
      .catch((e: any) => {
        this.isDisabled = false;
        console.log('err', e);
        this.utils.toast({
          message: e
            ? e
            : 'Ocorreu um erro ao gerar o pagamento! Por favor, verifique seus dados ou tente novamente mais tarde',
        });
      });
  }

  performClick(el: MatButton) {
    el._elementRef.nativeElement.click();
  }

  cancel() {
    this.router.navigate(['/' + this.vaquinha.url]);
  }

  popitup(url) {
    if (window) {
      const newwindow = window.open(
        url,
        'Confirmação de pagamento',
        'height=600,width=600'
      );
      if (newwindow && window.focus) {
        newwindow.focus();
      }
      return false;
    }
  }
  getCardToken(card) {
    return new Promise((resolve, reject) => {
      PagSeguroDirectPayment.createCardToken({
        cardNumber: card.CardNumber, // Número do cartão de crédito
        brand: card.CardBrand, // Bandeira do cartão
        cvv: card.SecurityCode, // CVV do cartão
        expirationMonth: card.ExpirationDate.split('/')[0], // Mês da expiração do cartão
        expirationYear: card.ExpirationDate.split('/')[1], // Ano da expiração do cartão, é necessário os 4 dígitos.
        success(response) {
          // Retorna o cartão tokenizado.
          console.log('token', response);
          resolve(response.card.token);
        },
        error(response) {
          console.log('tokenErr', response);
          reject(response);
          // Callback para chamadas que falharam.
        },
        complete(response) {
          // Callback para todas chamadas.
        },
      });
    });
  }
  getCardBrand(card) {
    return new Promise((resolve, reject) => {
      console.log('card.CardNumber.slice(0, 6)', card.CardNumber.slice(0, 6));
      PagSeguroDirectPayment.getBrand({
        cardBin: card.CardNumber.slice(0, 6),
        success(response) {
          console.log('brand', response.brand);
          resolve(response.brand.name);
          // bandeira encontrada
        },
        error(response) {
          console.log('branderr', response);
          reject(response);
          // tratamento do erro
        },
        complete(response) {
          // tratamento comum para todas chamadas
        },
      });
    });
  }
  encryptCard(card) {
    return new Promise((resolve, reject) => {
      const cardInfo = PagSeguro.encryptCard({
        publicKey:
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB',
        holder: card.CardName,
        number: card.CardNumber,
        expMonth: card.ExpirationDate.split('/')[0],
        expYear: card.ExpirationDate.split('/')[1],
        securityCode: card.SecurityCode,
      });

      if (cardInfo.hasErrors) {
        return reject(cardInfo.errors);
      }
      resolve(cardInfo.encryptedCard);
    });
  }
  toggle(item) {
    this.terms = item.checked;
  }
  OpenTerms() {
    const confirmDialog = this.dialog.open(ModalTermDonationComponent, {
      disableClose: false,
      data: {
        title: 'Bem-vindo (a) ao QueroApoiar! ',
        message:
          '<p>Perfil criado com sucesso! Para editar seus dados e começar a utilizar a plataforma, pague a taxa única de inscrição no valor de R$149,00. QueroApoiar, seu apoio começa aqui! </p>',
      },
    });
    confirmDialog.afterClosed().subscribe((result) => {
      console.log('RESULT: ', result);
      // if (result === 'false') { console.log("false") }
      // if(result === 'true') { console.log("true"), this.openDialogMAquininha() }
    });
  }
  DadosIncorrect() {
    if (this.CepValido == false) {
      this.utils.toast({
        message: 'CEP inválido, verifique os dados inseridos.',
      });
    }
  }
  prepareTransaction() {
    return new Promise((resolve, reject) => {
      this.pagseguroService.createRequest().then((r) => {
        console.log('session', r);
        PagSeguroDirectPayment.setSessionId(r.sessionId);
        PagSeguroDirectPayment.onSenderHashReady((response) => {
          if (response.status === 'error') {
            console.log(response.message);
            return false;
          }
          this.hash = response.senderHash; // Hash estará disponível nesta variável.
          console.log('hash', this.hash);
          resolve(this.hash);
        });
      });
    });
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => {
      if (this.copied) this.copied = false;
    }, 3000);
    console.log(this.copied);
  }

  getMinimumValue(valor, tipo) {
    console.log('type', tipo);
    if (tipo === 'boleto') return Number(valor) >= 60 ? true : false;
    if (
      tipo === 'pix' ||
      tipo === 'credit' ||
      String(this.vaquinha.id) == '62817bdc3716135f49c0c3e9' ||
      String(this.vaquinha.id) == '62804aa8714c6d42130490c7' ||
      String(this.vaquinha.id) == '627d69355132f804a088c367'
    ) {
      return Number(valor) >= 20 ? true : false;
    }
  }
}
