import { Component, Input /*Output, EventEmitter*/ } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GlobalProvider } from 'src/providers/globals';
import { DomainProvider } from 'src/providers/domains';
import { CouponService } from '../../providers/coupon.service';
import * as moment from 'moment';
// import { NgbDateFRParserFormatter } from "../../providers/datepicker-format";
// import { HttpProvider } from "../../providers/http";

/**
 * Generated class for the DefaultFormComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */

@Component({
  selector: 'default-form',
  templateUrl: 'default-form.html',
  styleUrls: ['./default-form.scss'],
  // providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}],
})
export class DefaultFormComponent {
  /*
		- TODO:
			- CREATE ION-SELECT FUNCTIONALITY
			- FIND OR CREATE A FUNCTIONING MASK FOR INPUTS
			- BACK, NEXT AND SUBMIT SHOULD RETURN PROMISES FOR BACKEND VALIDATION

		- Example of how to build the parameters for this component.

		- The *back*, *next* and *submit* are EventEmitter for the parent page. Can perform validations on back-end, for instance, checking for user"s e-mail

		- The *step* attribute should be an integer, depending on which step of the form you want to see. Usually it is 0, but for debug purposes it can be changed
			step = 0

		- The *forms* object is the most important attribute of this component. It should have these attributes:
			- The *title* attribute is the title of that form
				title: "Login Data"
			- The *fields* attribute builds the layout of the form accordingly
				fields: {name, label, icon, type}
				- Declaring explicitly the FormControls is not necessary, however in order to use CustomValidators plugin equalTo, for instance, it is necessary
			- The *builder* attribute receives a FormBuilder object to create the form validation. The attributes provided should match the *fields* attr
				builder: FormBuilder object
			- The *validation_messages* attribute receives an array for each form attribute, and each array should provide the messages to return when there is an error in each validation

		- Full Example:

					const email = new FormControl("", Validators.compose([Validators.required, Validators.email]))
					const confirm_email = new FormControl("", CustomValidators.equalTo(email))
					const password = new FormControl("", Validators.compose([Validators.required, Validators.minLength(6)]))
					const confirm_password = new FormControl("", CustomValidators.equalTo(password))

					const name = new FormControl("", Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)]))
					const phone = new FormControl("", Validators.compose([Validators.required, Validators.pattern(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/)]))

					step = 0
					forms = [
						{
							fields: [
								{
									name: "email",
									label: "E-mail",
									icon: "fa-envelope-o",
									type: "email"
								},
								{
									name: "confirm_email",
									label: "Confirmar e-mail",
									icon: "fa-envelope-o",
									type: "email"
								},
							],
							builder: this.formBuilder.group({
								email,
								confirm_email,
							}),
							validation_messages: {
								email: [
									{type: "required", message: "Preencha o campo e-mail."},
									{type: "email", message: "E-mail inválido."}
								],
								confirm_email: [
									{type: "equalTo", message: "Deve ser igual ao campo e-mail."}
								],
							}
						}
					]

					back() {
						this.step--
					}

					next() {
						this.step++
					}

					submit() {

					}
	*/

  @Input() forms: any;
  @Input() step: any;
  @Input() controller: any;
  // search = "";
  searchList = '';
  searchItens: any;
  public validCode: string = ''; // neutro , invalido
  result: any;
  total: any;
  delay: any;
  today: any = moment().format('yyyy-MM-DD');
  selectedItem: any;
  private search = [];
  private conteudo: Boolean = true;
  arrCities: any;
  busca: any;
  public customTextInfo: string;
  private timer;
  constructor(
    // private httpProvider: HttpProvider
    public global: GlobalProvider,
    private couponService: CouponService,
    private domainProvider: DomainProvider
  ) {
    console.log('Hello DefaultFormComponent Component');
    this.init();
  }
  init(): any {
    const self: any = this;
    if (this.controller) {
      this.controller.defaultForm = this;
    } else {
      setTimeout(() => {
        self.init();
      }, 500);
    }
  }

  getItensBySearch(url: string): any {
    clearTimeout(this.delay);
    // this.delay = setTimeout(() => {
    // 	const data: any = {find: this.search};
    // 	this.httpProvider.post(url, true, {data}).then((res: any) => {
    // 		console.log("RES: ", res);
    // 		this.searchItens = res.itens;
    // 		this.total = res.total;
    // 	})
    // 	.catch(err => { console.log(err); });
    // }, 500 );
  }
  getByEmailList(url: string, calback: any): any {
    clearTimeout(this.delay);
    // this.delay = setTimeout(() => {
    // 	const data: any = {find: this.searchList};
    // 	this.httpProvider.post(url, true, {data}).then((res: any) => {
    // 		if (res.itens) {
    // 			calback(res.itens);
    // 		}
    // 	})
    // 	.catch(err => { console.log(err); });
    // }, 500 );
  }
  getImage(item: any): any {
    return item.images[0]
      ? String(item.images[0].host) + String(item.images[0].thumb)
      : 'assets/images/default-avatar.png';
  }
  selectItem(item: any, callback: any): any {
    this.selectedItem = item;
    this.result = item._id;
    this.searchItens = undefined;
    if (callback) {
      callback(item);
    }
  }
  setValue(event, formB) {
    formB.value = event.target.value;
  }

  selectCheckBox(checkedValue, text) {
    if (checkedValue == text) {
      return true;
    }

    return false;
  }

  searchCity(busca) {
    console.log('aaaaaaaaa');
    const self = this;
    console.log('hits', this.busca);
    self.busca = busca;

    if (self.busca && self.busca.length >= 3) {
      this.global.showLoading();
      clearTimeout(self.timer);
      self.timer = setTimeout(async () => {
        self.search = [];
        let err, item;
        [err, item] = await self.global.to(
          self.domainProvider.searchCities({
            search: self.busca,
            page: 0,
            limit: 5,
          })
        );
        if (!err) {
          self.search = self.search.concat(item.cities);
        }
        this.global.hideLoading();
        self.arrCities = self.search;
        if (self.search.length === 0) {
          self.conteudo = false;
        } else {
          self.conteudo = true;
        }
      }, 1800);
    }
  }

  async searchCouponCode({ target }, field) {
    const self = this;
    target.value = target.value.toUpperCase();
    clearTimeout(self.timer);
    self.timer = setTimeout(async function () {
      const coupon = await self.couponService.exits({
        couponCode: target.value.toUpperCase(),
      });
      if (coupon != null) {
        self.validCode = 'valido';
        self.customTextInfo = coupon.discountAmount.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
        console.log('coupon valid', this.customTextInfo);
      } else {
        self.validCode = 'invalido';
      }

      field.onChange(coupon);
    }, 1500);
  }
}
