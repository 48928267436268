import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/providers/auth.service';
import { UtilsProvider } from 'src/commons/utils';
import { ENV } from 'src/environments/environment-variables.token';
import { UserService } from 'src/providers/user.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { VaquinhaService } from 'src/providers/vaquinha.service';
import { MainVaquinhaComponent } from '../modal-vaquinha/main-vaquinha/main-vaquinha.component';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { PoliticalService } from 'src/providers/political.service';
import * as moment from 'moment';
import { element } from 'protractor';

@Component({
  selector: 'app-my-vaquinha',
  templateUrl: './myVaquinha.component.html',
  styleUrls: ['./myVaquinha.component.scss'],
})
export class MyVaquinhaComponent implements OnInit {
  displayedColumns: string[] = [
    'image',
    'description',
    'status',
    'initAt',
    'endAt',
    'type',
    'view',
  ];

  public vaquinhaTypes: Array<Object> = [];

  // KNOW

  public list: any;

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource;
  public warningStatus: String = 'false';
  public screenSizeHeight: Number;

  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public dialog: MatDialog,
    public authProvider: AuthService,
    @Inject(ENV) public ENV: any,
    public utils: UtilsProvider,
    private router: Router,
    public vaquinhaProvider: VaquinhaService,
    public politicalProvider: PoliticalService
  ) {}

  @HostListener('window:resize')
  onWindowResize() {
    this.screenSizeHeight = window.innerHeight - 83;
  }

  ngOnInit(): void {
    this.onWindowResize();
    window.scrollTo(0, 0);
    if (!localStorage.getItem('user')) {
      this.router.navigate(['/entrar']);
    }
    this.ENV.active = 'vaquinhas';
    this.getVaquinha();
  }

  redirect(element) {
    this.router.navigate([`admin/minhas-vaquinhas/${element._id}`], {
      queryParams: element,
    });
  }

  showVaquinha(item) {
    if (item.active) {
      this.router.navigate([`${item.url}`]);
    } else {
      const dialogRef = this.dialog.open(ModalDefaultComponent, {
        autoFocus: false,
        data: {
          title: 'Taxa de inscrição',
          message:
            'As Vaquinhas requerem uma taxa de inscrição. Pague a taxa para visualizar a vaquinha.',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});
      return;
    }
  }

  verifyStatus(element) {
    const now = new Date().getFullYear();
    const year = new Date(element.year).getFullYear();
    console.log('element -', element);
    console.log('element -', year <= now);
    if (!element.paid && year <= now) {
      return 'Inativo';
    } else {
      return 'Ativo';
    }
  }

  showWarningStatus(id, show) {
    if (show) this.warningStatus = id;
    if (!show) this.warningStatus = '';
  }

  async getVaquinha() {
    const [err, listVaquinha] = await this.utils.tryCatch(
      this.vaquinhaProvider.getList()
    );

    if (err) {
      this.utils.toast({
        message: 'Erro ao listar vaquinhas.',
        action: '❌',
      });
    }

    this.list = listVaquinha;
    this.list.forEach((item) => {
      item.image.link = item.image.host + item.image.full;
    });

    this.dataSource = new MatTableDataSource(this.list);
    this.dataSource.paginator = this.paginator;
  }

  async openDialog(data, edit, create, eleitoral) {
    let dayPos;
    const vaqType = [];

    const [err, getType] = await this.utils.tryCatch(
      this.vaquinhaProvider.getType()
    );

    if (err) return console.log('getType error,', err);
    console.log('getType', getType);

    getType.map((item) => {
      const value = {
        _id: item._id,
        name: item.name,
      };

      if (item.name == 'Eleitoral' && eleitoral === true) {
        vaqType.push(value);
      } else if (item.name == 'Normal' && eleitoral === false) {
        vaqType.push(value);
      }
    });

    if (eleitoral == true) {
      new Promise(async (resolve, reject) => {
        const [err, period] = await this.utils.tryCatch(
          this.politicalProvider.getElectionPeriod()
        );

        dayPos = moment().isAfter(moment(period.initAt).toDate());

        if (dayPos == false) {
          this.utils.toast({
            message: `Fora de período Eleitoral!`,
            action: '❌',
            duration: 8000,
          });
        } else {
          const confirmDialog = this.dialog.open(
            MainVaquinhaComponent /* ModalViewVaquinhaComponent */,
            {
              data: {
                ...data,
                edit: edit,
                create: create,
                vaquinhaTypes: this.vaquinhaTypes,
                eleitoral: eleitoral,
                name: 'Eleitoral',
                vaqType: vaqType,
              },
            }
          );
          confirmDialog.afterClosed().subscribe((result) => {
            this.getVaquinha();
            if (result === 'false') {
              return;
            }
          });
        }

        if (err) reject(-1);
        resolve(period);
      });
    } else {
      const confirmDialog = this.dialog.open(
        MainVaquinhaComponent /* ModalViewVaquinhaComponent */,
        {
          data: {
            ...data,
            edit: edit,
            create: create,
            vaquinhaTypes: this.vaquinhaTypes,
            eleitoral: eleitoral,
            name: 'Normal',
            vaqType: vaqType,
          },
        }
      );
      confirmDialog.afterClosed().subscribe((result) => {
        this.getVaquinha();
        if (result === 'false') {
          return;
        }
      });
    }
  }

  // public openModal() {
  //   const dialogRef = this.dialog.open(SaqueModalComponent, {
  //     data: { available: this.available > 0 ? this.available : 0 },
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {});
  // }
}
